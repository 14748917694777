

// Viewer3D offers public methods for developers to use.
// Viewer3DImpl is the implementation file for Viewer3D and is only used by Viewer3D.js
// 
// Viewer3D does things like parameter validation.
// Viewer3DImpl does the actual work, by interfacing with other internal components, such as the MaterialManager.

import '@babel/polyfill'; // for async
import * as THREE from "three";
import { isMobileDevice, isTouchDevice, isChrome, isIOSDevice } from "../compat";
import { DefaultLightPreset } from "./LightPresets";
import { logger } from "../logger/Logger";
import { Preferences } from "./Preferences";
import { getParameterByName } from "../globals";
import { errorCodeString, ErrorCodes } from "../file-loaders/net/ErrorCodes";
import { EventDispatcher } from "./EventDispatcher";
import { ScreenModeMixin, ScreenMode } from "./ScreenModeDelegate";
import { ExtensionMixin } from "./ExtensionManager";
import { HotkeyManager } from "../tools/HotkeyManager";
import { isNodeJS } from "../compat";
import { Navigation } from "../tools/Navigation";
import { ToolController } from "../tools/ToolController";
import { ViewingUtilities } from "../tools/ViewingUtilities";
import { DefaultHandler } from "../tools/DefaultHandler";
import { GestureHandler } from "../tools/GestureHandler";
import { i18n } from "../globalization/i18next";
import { endpoint, getEnv } from "../file-loaders/net/endpoints";
import * as et from "./EventTypes";
import { FileLoaderManager } from "./FileLoaderManager";
import { clearPropertyWorkerCache } from "../file-loaders/main/PropDbLoader";
import { loadDependency } from "../globals";
import { ViewerState } from "./ViewerState";
import { LiveReviewClient } from "../net/LiveReviewClient";
import { OrbitDollyPanTool } from "../tools/OrbitDollyPanTool";
import { Autocam } from "../tools/autocam/Autocam";
import { Viewer3DImpl } from "./Viewer3DImpl";
import { BubbleNode } from "./bubble";
import { Consolidation } from "../wgs/scene/Consolidation";
import { KeyCode } from "../tools/KeyCode";
import { LoadingSpinner } from './LoadingSpinner';
import { SceneMath } from "../wgs/scene/SceneMath";
import { ScreenShot } from "./ScreenShot";
import loaderExtensions from '../file-loaders/loader-extensions';
import { OverlayManager } from './OverlayManager';
    /**
     * Detects if WebGL is enabled.
     *
     * @return { number } -1 for not Supported,
     *                    0 for disabled
     *                    1 for enabled
     * 
     * @private
     */
    function detectWebGL()
    {
        // Check for the webgl rendering context
        if ( window.WebGLRenderingContext) {
            var canvas = document.createElement("canvas"),
                names = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"],
                context = false;

            for (var i = 0; i < 4; i++) {
                try {
                    context = canvas.getContext(names[i]);
                    if (context && typeof context.getParameter === "function") {
                        // WebGL is enabled.
                        //
                        return 1;
                    }
                } catch (e) {}
            }

            // WebGL is supported, but disabled.
            //
            return 0;
        }

        // WebGL not supported.
        //
        return -1;
    }


    var nextViewerId = 0;

    export let DefaultSettings = {
        "ambientShadows": true,
        "antialiasing": !isMobileDevice(),
        "groundShadow": true,
        "groundReflection": false,
        "progressiveRendering": true,
        "swapBlackAndWhite": false,
        "openPropertiesOnSelect": false,
        "ghosting": true,
        "viewCube": !isMobileDevice(),
        "lineRendering": true,
        "pointRendering": true,
        "edgeRendering": BUILD_FLAG__AEC_NAVIGATION,
        "grayscale": false,
        "lightPreset": DefaultLightPreset,
        "backgroundColorPreset": null,
        "reverseMouseZoomDir": BUILD_FLAG__AEC_NAVIGATION,
        "reverseHorizontalLookDirection": false,
        "reverseVerticalLookDirection": false,
        "alwaysUsePivot": BUILD_FLAG__AEC_NAVIGATION,
        "zoomTowardsPivot": false,
        "orbitPastWorldPoles": true,
        "leftHandedMouseSetup": false,
        "clickToSetCOI": false,
        "optimizeNavigation": isMobileDevice(),
        "fusionOrbit": true,
        "fusionOrbitConstrained": true,
        "envMapBackground" : false,
        "firstPersonToolPopup" : true,
        "bimWalkToolPopup" : true,
        "wheelSetsPivot": BUILD_FLAG__AEC_NAVIGATION
    };

    // Order in which elements will appear - last node is the top most
    const DefaultContainerLayersOrder = [
        'markups-svg',
        'pushpin-container'
    ];

    export function isMiddleClick(event) {
        if (!(event instanceof MouseEvent))
            return false;

        return event.button === 1;
    }

    var __firefoxLMBfix = false;
    export function isRightClick(event, navigation) {
        if (!(event instanceof MouseEvent))
            return false;

        var button = event.button;

        // Check for Firefox spoof: Control+LMB converted to RMB.
        // The "buttons" property in Firefox will include 1 for LMB and 2 for RMB.
        if( "buttons" in event ) {
            // For button down the 1 bit will be on indicating LMB.
            // For button up it's off so check the flag to see if we
            // switched the down event.
            if( __firefoxLMBfix && !(event.buttons & 1) ) { // Button up?
                __firefoxLMBfix = false;
                button = 0;
            }
            else if( (button === 2) && (event.buttons & 1) ) {
                button = 0;    // Convert back to reality.
                __firefoxLMBfix = true;
            }
        }

        var useLeftHandedInput = navigation ? navigation.getUseLeftHandedInput() : false;
        var rightButton = useLeftHandedInput ? 0 : 2;

        return button === rightButton;
    }

    /**
     * Base class for all viewer implementations.
     * It contains everything that is needed to connect to the Autodesk's Forge service and display 2D and 3D models.
     * It also includes basic navigation support, context menu and extension APIs.
     * 
     * @constructor
     * 
     * @param {HTMLElement} container - The viewer container.
     * @param {ViewerConfig} config - The initial settings object.
     * @param {boolean} [config.startOnInitialize=true] - Set this to false if you want to defer the run to a later time
     * by calling run() explicitly.
     * @param {string} [config.theme='dark-theme'] - Set this to 'light-theme' if you want to use the light ui theme. Themes can
     * be changed during execution by calling setTheme() and passing the theme's name.
     * 
     * @property {Autodesk.Viewing.Navigation} navigation - The navigation api object.
     * @property {Autodesk.Viewing.ToolController} toolController - The tool controller object.
     * @property {Autodesk.Viewing.ViewingUtilities} utilities - The viewing utilities object.
     * @property {Autodesk.Viewing.Model} model - Property that references the first loaded model.
     * @property {Autodesk.Viewing.OverlayManager} overlays - Add/Remove `THREE.Mesh` instances into overlay scenes.
     * 
	 * @alias Autodesk.Viewing.Viewer3D
     */
    export function Viewer3D(container, config)
    {
        if (container) {
            this.clientContainer = container;
            this.container = document.createElement("div");
            this.container.classList.add("adsk-viewing-viewer");
            this.container.style.height = "100%";
            this.container.style.width = "100%";
            this.container.style.overflow = "hidden";

            this.container.classList.add( isTouchDevice() ? "touch" : "notouch");

            this.clientContainer.appendChild(this.container);

            this.config = config || {};
            this.contextMenu = null;
            this.contextMenuCallbacks = {};
            this.started = false;

            // Set the UI theme.
            this.theme = this.config.theme || 'dark-theme';
            this.container.classList.add(this.theme);

            this.containerLayerOrder = this.config.containerLayerOrder || DefaultContainerLayersOrder;

            if (isChrome()) {
                this.container.classList.add('quality-text');
            }

            // Create the canvas if it doesn't already exist
            if ( this.container.nodeName === "CANVAS") {
                throw 'Viewer must be initialized on a div [temporary]';
            }
            else
            {
                this.canvasWrap = document.createElement("div");
                this.canvasWrap.classList.add("canvas-wrap");

                this.canvas = document.createElement("canvas");
                this.canvas.tabIndex = 0;
                this.canvas.setAttribute('data-viewer-canvas', 'true');

                this.canvasWrap.appendChild(this.canvas);
                this.container.appendChild(this.canvasWrap);
            }

            this.canvas.viewer = this; //store a pointer to the viewer in the canvas
        }

        var prefOptions = {
            // Preferences. Prefix is a bit odd, but a legacy result after refactoring.
            prefix: 'Autodesk.Viewing.Private.GuiViewer3D.SavedSettings.',
            localStorage: !isNodeJS()
        };
        this.prefs = new Preferences(this, prefOptions);

        // Add a reference to the hotkey manager as an instance variable
        this._hotkeyManager = new HotkeyManager();

        this.extensionCache = null; // Supports Extension.getCache() feature.
        this.running = false;
        this._pushedTool = '';
        this._defaultNavigationTool = '';
        this.id = nextViewerId++;
        this.impl = new Viewer3DImpl(this.canvas, this);
        this.overlays = new OverlayManager(this.impl);
        this._loadingSpinner = new LoadingSpinner();

        //ADP
        this.trackADPTimer = [];
    };

    EventDispatcher.prototype.apply( Viewer3D.prototype );
    ScreenModeMixin.prototype.apply( Viewer3D.prototype );
    ExtensionMixin.prototype.apply( Viewer3D.prototype );

    Viewer3D.prototype.constructor = Viewer3D;

    Object.defineProperty(Viewer3D.prototype, 'model', {
        get: function() { return this.impl.model; },
        set: function() { throw "Do not set viewer.model"; }
    });

    /**
     * Need to keep track of viewers in document so we know when it is safe
     * to call clearPropertyWorkerCache()
     *
     * @static
     * @alias Autodesk.Viewing.Viewer3D#ViewerCount
     */
    Viewer3D.ViewerCount = 0;

    /**
     * Default values passed into {@link #setCanvasClickBehavior} specifying how the viewer canvas 
     * will react to user input as well as other 3d-canvas related options.
     *
     * @static
     * @alias Autodesk.Viewing.Viewer3D#kDefaultCanvasConfig
     */
    Viewer3D.kDefaultCanvasConfig = {
        "click": {
            "onObject": ["selectOnly"],
            "offObject": ["deselectAll"]
        },
        "clickAlt": {
            "onObject": ["setCOI"],
            "offObject": ["setCOI"]
        },
        "clickCtrl": {
            "onObject": ["selectToggle"]
            // don't deselect if user has control key down https://jira.autodesk.com/browse/LMV-1852
            //"offObject": ["deselectAll"]
        },
        "clickShift": {
            "onObject": ["selectToggle"]
            // don't deselect if user has shift key down https://jira.autodesk.com/browse/LMV-1852
            //"offObject": ["deselectAll"]
        },

        // Features that support disabling
        "disableSpinner": false,
        "disableMouseWheel": false,
        "disableTwoFingerSwipe": false
    };

    /**
     * @private
     */
    Viewer3D.createHeadlessViewer = function() {
        var viewer = new Viewer3D();
        viewer.impl.initialize();
        viewer.impl.setLightPreset(0);
        return viewer;
    };

    /**
     * @callback Autodesk.Viewing.Viewer3D~onLoadModelSuccess
     * @param {Autodesk.Viewing.Model} model - Reference to the loaded model.
     */

    /**
     * @callback Autodesk.Viewing.Viewer3D~onLoadModelFailure
     * @param {Number} errorCode - error number
     * @param {string} errorMessage - error message
     */

    /**
     * Initializes the viewer and loads any extensions specified in the constructor's
     * config parameter. If the optional parameters are specified, the start() function will
     * use an optimized initialization sequence that results in faster model load.
     * The parameters are the same as the ones for Viewer3D.loadModel and you do not need to call loadModel
     * subsequently if the model is loaded via the call to start().
     *
     * @param {string} [url] - Optional URN or filepath to load on start.
     * @param {string} [options] - Optional path to shared property database.
     * @param {Autodesk.Viewing.Viewer3D~onLoadModelSuccess} [onSuccessCallback] - Method that gets called when initial loading is done
     * and streaming starts.
     * @param {Autodesk.Viewing.Viewer3D~onLoadModelFailure} [onErrorCallback] - Method that gets called when initial loading ends with an error.
     * @returns {number} 0 if the viewer has started, an error code (same as that returned by initialize()) otherwise.
     * 
     * @alias Autodesk.Viewing.Viewer3D#start
     */
    Viewer3D.prototype.start = function (url, options, onSuccessCallback, onErrorCallback) {
        if (this.started) {
            return 0;
        }
        this.started = true;

        var viewer = this;

        // Initialize the renderer and related stuff
        var result = viewer.initialize();
        if (result !== 0) {
            if (onErrorCallback) {
                setTimeout(function(){ onErrorCallback(result); }, 1);
            }
            return result;
        }

        //load extensions and set navigation overrides, etc.
        //Delayed so that it runs a frame after the long initialize() call.
        setTimeout(function() {viewer.setUp(viewer.config);}, 1);

        //If a model URL was given, kick off loading first, then initialize, otherwise just continue
        //with initialization immediately.
        if (url)
            this.loadModel(url, options, onSuccessCallback, onErrorCallback);

        return 0;
    };


    /**
     * Initializes the viewer and loads any extensions specified in the constructor's
     * config parameter. If the optional parameters are specified, the start() function will
     * use an optimized initialization sequence that results in faster model load.
     * The parameters are the same as the ones for Viewer3D.loadModel and you do not need to call loadModel
     * subsequently if the model is loaded via the call to start().
     * 
     * @param {Document} avDocument - The Document instance holding the current derivative manifest
     * @param {BubbleNode} [manifestNode] - The manifest node to load model for.
     * @param {Object} [options] - Extra initialization options to override the defaults. Normally not needed.
     * @returns {Promise} - Resolves on success, rejects on any kind of initialization failure.
     * 
     */
     Viewer3D.prototype.startWithDocumentNode = function (avDocument, manifestNode, options) {

        var viewer = this;

        return new Promise(function(resolve, reject) {

            if (viewer.started) {
                return 0;
            }
            viewer.started = true;
    
            // Initialize the renderer and related stuff
            var result = viewer.initialize();
            if (result !== 0) {
                setTimeout(function(){ reject(result); }, 1);
                return;
            }
    
            //load extensions and set navigation overrides, etc.
            //Delayed so that it runs a frame after the long initialize() call.
            setTimeout(function() {viewer.setUp(viewer.config);}, 1);
    
            //If a model URL was given, kick off loading first, then initialize, otherwise just continue
            //with initialization immediately.
            var prom = viewer.loadDocumentNode(avDocument, manifestNode, options);
            prom.then(resolve).catch(reject);
        });
    };


    Viewer3D.prototype.registerUniversalHotkeys = function()
    {
        var self = this;

        var onPress;
        var onRelease;
        var previousTool;

        /*
        // useful for debugging, when you want to force a redraw, hit "u"
        // search on ""Autodesk.ForceUpdate" below and uncomment that popHotkeys line, too.
        // Add force update hotkey
        onPress = function() {
            // _spectorDump: the fourth "true" gets Spector to dump, if uncommented in Viewer3DImpl.js
            self.impl.invalidate(true,true,true,true);
            return true;
        };
        this._hotkeyManager.pushHotkeys("Autodesk.ForceUpdate", [
            {
                keycodes: [KeyCode.u],
                onPress: onPress
            }
        ]);
        */


        // Add Fit to view hotkey
        onPress = function() {
            self.navigation.setRequestFitToView(true);
            return true;
        };
        self._hotkeyManager.pushHotkeys("Autodesk.FitToView", [
            {
                keycodes: [KeyCode.f],
                onPress: onPress
            }
        ]);

        // Add home hotkey
        onPress = function() {
            self.navigation.setRequestHomeView(true);
            return true;
        };
        self._hotkeyManager.pushHotkeys("Autodesk.Home", [
            {
                keycodes: [KeyCode.h],
                onPress: onPress
            },
            {
                keycodes: [KeyCode.HOME],
                onPress: onPress
            }
        ]);

        // Escape
        onRelease = function() {
            // handle internal GUI components before firing the event to the client
            if (self.objectContextMenu && self.objectContextMenu.hide()) {
                return true;
            }

            // TODO: Could this all be unified somehow? If event listeners had priorities,
            //       we could intersperse listeners from the client and the viewer, which
            //       I think will eventually be required.

            self.dispatchEvent({ type: et.ESCAPE_EVENT });
            return true;
        };

        self._hotkeyManager.pushHotkeys("Autodesk.Escape", [
            {
                keycodes: [KeyCode.ESCAPE],
                onRelease: onRelease
            }
        ]);

        // Pan
        onPress = function() {
            previousTool = self.getActiveNavigationTool();
            return self.setActiveNavigationTool("pan");
        };
        onRelease = function() {
            return self.setActiveNavigationTool(previousTool);
        };
        var hotkeys = [
            {
                keycodes: [KeyCode.SHIFT],
                onPress: onPress,
                onRelease: onRelease
            },
            {
                keycodes: [KeyCode.SPACE],
                onPress: onPress,
                onRelease: onRelease
            }];
        self._hotkeyManager.pushHotkeys("Autodesk.Pan", hotkeys, {tryUntilSuccess: true});
    };

    Viewer3D.prototype.createControls = function( ) {
        var self = this;
        var impl = self.impl;

        self.navigation = new Navigation(impl.camera);
        self.__initAutoCam(impl);

        self.utilities = new ViewingUtilities(impl, self.autocam, self.navigation);
        self.clickHandler = new DefaultHandler(impl, self.navigation, self.utilities);
        self.toolController = new ToolController(impl, self, self.autocam, self.utilities, self.clickHandler);
        self.toolController.registerTool( new GestureHandler(self) );

        self.toolController.registerTool( self._hotkeyManager );
        self.toolController.activateTool( self._hotkeyManager.getName() );

        self.registerUniversalHotkeys();

        self.toolController.registerTool( new OrbitDollyPanTool(impl, self, this.config.navToolsConfig) );

        return self.toolController;
    };

    /**
     * Create any DOM and canvas elements, and setup WebGL.
     *
     * @returns {number} 0 if initialization was successful, {@link Autodesk.Viewing.ErrorCode} otherwise.
     * @private
     */
    Viewer3D.prototype.initialize = function()
    {

        //Set up the private viewer implementation
        this.setScreenModeDelegate(this.config ? this.config.screenModeDelegate : undefined);

        var dimensions = this.getDimensions();
        this.canvas.width = dimensions.width;
        this.canvas.height = dimensions.height;

        // For Safari and WKWebView and UIWebView on ios device with retina display,
        // needs to manually rescale our canvas to get the right scaling. viewport metatag
        // alone would not work.
        if (isIOSDevice() && window.devicePixelRatio) {
            this.canvas.width /= window.devicePixelRatio;
            this.canvas.height /= window.devicePixelRatio;
        }

        //Call this after setting canvas size above...
        this.impl.initialize();

        //Only run the WebGL failure logic if the renderer failed to initialize (otherwise
        //we don't have to spend time creating a GL context here, since we know it worked already
        if (!this.impl.glrenderer()) {
            var webGL = detectWebGL();
            if (webGL <= 0) {  // WebGL error.
                return webGL === -1 ? ErrorCodes.BROWSER_WEBGL_NOT_SUPPORTED : ErrorCodes.BROWSER_WEBGL_DISABLED;
            }
        }

        var self = this;

        // Add a callback for the panels to resize when the viewer resizes.
        // For some reason, Safari iOS updates the DOM dimensions *after* the resize event,
        // so in that case we handle the resizing asynchronously.
        if (isIOSDevice()) {
            var _resizeTimer;
            this.onResizeCallback = function(e) {
                clearTimeout(_resizeTimer);
                _resizeTimer = setTimeout(self.resize.bind(self), 500);
            };
        } else {
            this.onResizeCallback = function(e) {
                var oldWidth = self.impl.camera.clientWidth;
                var oldHeight = self.impl.camera.clientHeight;
                var newWidth = self.container.clientWidth;
                var newHeight =  self.container.clientHeight;

                if (oldWidth !== newWidth ||
                    oldHeight !== newHeight) {
                    self.resize();
                }
            };
        }
        window.addEventListener('resize', this.onResizeCallback, false);

        this.onScrollCallback = function(e) {
            self.impl.canvasBoundingclientRectDirty = true;
        };
        window.addEventListener('scroll', this.onScrollCallback);

        this.initContextMenu();

        // Localize the viewer.
        this.localize();


        this.impl.controls = this.createControls();
        this.setDefaultNavigationTool( "orbit" );

        if( this.impl.controls )
            this.impl.controls.setAutocam(this.autocam);

        var canvasConfig = (this.config && this.config.canvasConfig) ? this.config.canvasConfig : Viewer3D.kDefaultCanvasConfig;
        this.setCanvasClickBehavior(canvasConfig);


        // Allow clients not load the spinner. This is needed for embedding viewer in a WebView on mobile,
        // where the spinner makes the UI looks less 'native'.
        if (!canvasConfig.disableSpinner) {

            // Create a div containing an image: this will be a
            // spinner (aka activity indicator) that tells the user
            // that the file is loading.
            //
            // Keep reference for backwards compatibility.
            this.loadSpinner = this._loadingSpinner.createDom(this.container);
        }

        // Setup of AO, Ghosting, Env Lighting etc.
        this.initSettings();

        // Auxiliary class to get / restore the viewer state.
        this.viewerState = new ViewerState( this );

        // The default behavior is to run the main loop immediately, unless startOnInitialize
        // is provided and is false.
        //
        if (!this.config || !this.config.hasOwnProperty("startOnInitialize") || this.config.startOnInitialize)
        {
            this.run();
        }

        window.NOP_VIEWER = this;

        this.addEventListener(et.MODEL_ADDED_EVENT, function(e) {
            self.onModelAdded(e.model, e.preserveTools);
        });

        this.dispatchEvent(et.VIEWER_INITIALIZED);

        this.trackADPSettingsOptions();
        this.trackADPExtensionsLoaded();

        Viewer3D.ViewerCount++;

        // These calls are useful for Internet Explorer's use of spector.
        // Uncomment this code, and add the https://spectorcdn.babylonjs.com/spector.bundle.js script
        // in index.html, and Spector's menu shows up in the application itself.
        /*
        var spector = new SPECTOR.Spector();
        window.spector = spector;
        spector.displayUI();    // comment this line out if you instead want to use _spectorDump and the "u" key
        spector.spyCanvases();
        */

        return 0;   // No Error initializing.
    };

    /**
     * Loading extensions and initializing canvas interactions.
     * Invoked automatically by {@link Autodesk.Viewing.Viewer3D#start} method.
     * 
     * @param {object} [config] - configuration values 
     * @param {string[]} [config.extensions] - List of extension ids to load with the model.
     * @param {object} [config.canvasConfig] - Overrides for click/tap events on the 3D canvas. Refer to {@link #setCanvasClickBehavior} for details.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setUp
     */
    Viewer3D.prototype.setUp = function (config) {

        this.config = config || {};

        // Load the extensions specified in the config.
        //
        if (Array.isArray(this.config.extensions)) {
            var extensions = this.config.extensions;
            for (var i = 0; i < extensions.length; ++i) {
                this.loadExtension(extensions[i], this.config);
            }
        }

        //load debug ext by query param
        var debugConfig = getParameterByName("lmv_viewer_debug");
        if (debugConfig === "true") {
            this.loadExtension("Autodesk.Debug", this.config);
        }

        var canvasConfig = this.config.canvasConfig || Viewer3D.kDefaultCanvasConfig;
        this.setCanvasClickBehavior(canvasConfig);
    };

    /**
     * Unloads extensions and the model loaded.
     * Invoked automatically by {@link Autodesk.Viewing.Viewer3D#finish} method.
     * 
     * @alias Autodesk.Viewing.Viewer3D#tearDown
     */
    Viewer3D.prototype.tearDown = function () {
        this.clearSelection();

        if (this.loadedExtensions) {
            for (var extensionId in this.loadedExtensions) {
                try {
                    // Extensions that fail to unload will end up terminating
                    // the viewer tearDown process.  Thus we protect from it
                    // here and log it (if available).
                    this.unloadExtension(extensionId);
                } catch (err) {
                    logger.error("Failed to unload extension: " + extensionId, err, errorCodeString(ErrorCodes.VIEWER_INTERNAL_ERROR));
                    logger.track(
                        {
                            category:"error_unload_extension",
                            extensionId: extensionId,
                            error_message: err.message,
                            call_stack: err.stack
                        });
                }
            }
            this.loadedExtensions = null;
        }

        // Deactivate mouse events and touch gestures. If a new model will be loaded, it will be enabled again (activateDefaultNavigationTools).
        if (this.toolController) {
            this.toolController.enableMouseButtons(false);
            this.toolController.deactivateTool("gestures");
        }

        logger.reportRuntimeStats(true);

        this._loadingSpinner.show();

        if (this.liveReviewClient) {
            this.liveReviewClient.destroy();
            this.liveReviewClient = null;
        }

        //Stop ADP tracking
        while(this.trackADPTimer.length > 0) {
            clearTimeout(this.trackADPTimer.pop());
        }

        this.impl.unloadCurrentModel();
        this.impl.setCutPlaneSet('__set_view', undefined);
    };

    /**
     * Triggers the Viewer's render loop.
     * Invoked automatically by {@link Autodesk.Viewing.Viewer3D#start} method.
     * Refer to {@link ViewerConfig|ViewerConfig.startOnInitialize} to change start's method behavior.
     * 
     * @alias Autodesk.Viewing.Viewer3D#run
     */
    Viewer3D.prototype.run = function()
    {
        if( !this.running ) {
            this.resize();
            this.running = true;
            this.impl.run();
        }
    };


    /**
     * Localize the viewer. This method can be overwritten so that the subclasses
     * can localize any additional elements. Invoked internally during initialization.
     * 
     * @alias Autodesk.Viewing.Viewer3D#localize
     */
    Viewer3D.prototype.localize = function()
    {
        i18n.localize();
    };

    Viewer3D.prototype.__initAutoCam = function(impl)
    {
        var self = this;

        var ourCamera = impl.camera;

        if( !ourCamera.pivot )
            ourCamera.pivot = new THREE.Vector3(0, 0, 0);

        if( !ourCamera.target )
            ourCamera.target = new THREE.Vector3(0, 0, 0);

        if( !ourCamera.worldup )
            ourCamera.worldup = ourCamera.up.clone();

        function autocamChange(upChanged)
        {
            if( self.autocamCamera.isPerspective !== ourCamera.isPerspective )
            {
                if( self.autocamCamera.isPerspective )
                    self.navigation.toPerspective();
                else
                    self.navigation.toOrthographic();
            }
            self.navigation.setVerticalFov(self.autocamCamera.fov, false);
            self.navigation.setView(self.autocamCamera.position, self.autocamCamera.target);
            self.navigation.setPivotPoint(self.autocamCamera.pivot);
            self.navigation.setCameraUpVector(self.autocamCamera.up);
            if( upChanged )
                self.navigation.setWorldUpVector(self.autocamCamera.worldup);

            self.impl.syncCamera(upChanged);
        }

        function pivotDisplay(state)
        {
            if( self.utilities )
                self.utilities.pivotActive(state, false);
            else
                self.impl.controls.pivotActive(state, false);
        }

        function onTransitionCompleted() {
            self.fireEvent({ type: et.CAMERA_TRANSITION_COMPLETED });
        }

        self.autocamCamera = ourCamera.clone();
        self.autocamCamera.target = ourCamera.target.clone();
        self.autocamCamera.pivot  = ourCamera.pivot.clone();
        self.autocamCamera.worldup = ourCamera.worldup.clone();

        self.autocam  = new Autocam(self.autocamCamera, self.navigation, self.canvas);
        self.autocam.registerCallbackCameraChanged(autocamChange);
        self.autocam.registerCallbackPivotDisplay(pivotDisplay);
        self.autocam.registerCallbackTransitionCompleted(onTransitionCompleted);

        self.addEventListener("cameraChanged", function(evt)
        {
            var ourCamera = evt.camera;
            self.autocam.sync(ourCamera);
        });

        self.autocam.sync(ourCamera);
    };


    /**
     * Removes all created DOM elements and performs any GL uninitialization that is needed.
     * 
     * @alias Autodesk.Viewing.Viewer3D#uninitialize
     */
    Viewer3D.prototype.uninitialize = function()
    {

        window.removeEventListener('resize', this.onResizeCallback, false);
        this.onResizeCallback = null;

        window.removeEventListener('scroll', this.onScrollCallback);
        this.onScrollCallback = null;

        if(this.canvas) {
            this.canvas.removeEventListener('mousedown', this.onMouseDown); 
        } else if(this.container) {
            this.container.removeEventListener('mousedown', this.onMouseDown);
        }
        this.onMouseDown = null;

        if(this.canvas) {
            this.canvas.removeEventListener('mouseup', this.onMouseUp); 
        } else if(this.container) {
            this.container.removeEventListener('mouseup', this.onMouseUp);
        }
        this.onMouseUp = null;

        this.canvas.parentNode.removeChild(this.canvas);
        this.canvas.viewer = null;
        this.canvas = null;
        this.canvasWrap = null;

        this.viewerState = null;

        logger.reportRuntimeStats();
        logger.track({category:"viewer_destroy"}, true);

        if( this.toolController ) {
            this.toolController.uninitialize();
            this.toolController = null;
            this.clickHandler = null;
            this.utilities = null;
        }

        if (this.navigation) {
            this.navigation.uninitialize();
            this.navigation = null;
        }

        if (this.impl){
            this.impl.dtor();
            this.impl = null;
        }

        if (this.overlays) {
            this.overlays.dtor();
            this.overlays = null;
        }
        
        this.loadSpinner = null;
        this._loadingSpinner.destroy();
        this.prefs = null;

        this.autocam.dtor();
        this.autocam = null;
        this.autocamCamera = null;

        //this._hotkeyManager.popHotkeys("Autodesk.ForceUpdate");
        this._hotkeyManager.popHotkeys("Autodesk.FitToView");
        this._hotkeyManager.popHotkeys("Autodesk.Home");
        this._hotkeyManager.popHotkeys("Autodesk.Escape");
        this._hotkeyManager.popHotkeys("Autodesk.Pan");
        this._hotkeyManager.popHotkeys("Autodesk.Orbit");

        Viewer3D.ViewerCount--;
        if (Viewer3D.ViewerCount === 0) {
            clearPropertyWorkerCache();
        }

        if (this.onDefaultContextMenu) {
            this.container.removeEventListener('contextmenu', this.onDefaultContextMenu, false);
            this.onDefaultContextMenu = null;
        }

        if (this.screenModeDelegate) {
            this.screenModeDelegate.uninitialize();
            this.screenModeDelegate = null;
        }

        this.extensionCache = null;
        this.clientContainer = null;
        this.config = null;
        this.contextMenu = null;
        this.contextMenuCallbacks = null;

        if (this.container && this.container.parentNode)
            this.container.parentNode.removeChild(this.container);
        this.container = null;

        this.dispatchEvent(et.VIEWER_UNINITIALIZED);

        //forget all event listeners
        this.listeners = {};

        logger.log("viewer destroy");
    };


    /**
     * Unloads any loaded extensions and then uninitializes the viewer.
     * 
     * @alias Autodesk.Viewing.Viewer3D#finish
     */
    Viewer3D.prototype.finish = function() {
        this.tearDown();
        this.uninitialize();
    };


    Viewer3D.prototype.setLoadHeuristics = function(options) {

        //Check for source file extension -- Revit and Navisworks are AEC/BIM models
        var bubbleNode = options.bubbleNode;
        if (bubbleNode) {
            var viewable = bubbleNode.findViewableParent();
            if (viewable) {
                var fileName = viewable.name();
                options.fileExt = fileName.slice(fileName.length - 3).toLowerCase();
                var AECextensions = ['rvt', 'nwd', 'nwc', 'ifc'];
                if (typeof options.isAEC === "undefined" && AECextensions.indexOf(options.fileExt) !== -1) {
                    options.isAEC = true;
                }
            }
        }


        if (options.isAEC) {
            //If it's an AEC model, use mesh consolidation unless explicitly turned off
            let cparam = getParameterByName("useConsolidation") || "";
            options.useConsolidation = cparam === "true" ? true : (cparam === "false" ? false : undefined);

            if (typeof options.useConsolidation === "undefined") {
                options.useConsolidation = !isMobileDevice() && !isNodeJS(); //Consolidation requires a renderer, hence skip on node.js
            }
            if (typeof options.createWireframe === "undefined") {
                options.createWireframe = !isMobileDevice();
            }

            options.disablePrecomputedNodeBoxes = true;
        } else {

            if (typeof options.createWireframe === "undefined") {
                options.createWireframe = !isMobileDevice() && this.prefs.edgeRendering;
            }
        }

        // When using consolidation, a too fine-grained bvh would eliminate the performance gain.
        // To avoid that, we use larger default settings when activating consolidation.
        //
        // Doing this for consolidation only is done to minimize the scope of potential side effects whenever consolidation is not used.
        // It might generally be useful to increase these values, but this requires more investigation of potential performance impact first.
        if (options.useConsolidation && !options.bvhOptions) {
            options.bvhOptions = {};
            Consolidation.applyBVHDefaults(options.bvhOptions);
        }

    };

    /**
     * ADP
     */
    Viewer3D.prototype.trackADPSettingsOptions = function () {
        var self = this;
        this.trackADPTimer.push(setTimeout(function () {
            if(self.prefs) {
                var settingOptionsStatus = {
                    category: "settingOptionsStatus",
                    switchSheetColorWhiteToBlack: self.prefs.swapBlackAndWhite,
                    leftHandedMouseSetup: self.prefs.leftHandedMouseSetup,
                    openPropertiesOnSelect: self.prefs.openPropertiesOnSelect,
                    orbitPastWorldPoles: self.prefs.orbitPastWorldPoles,
                    reverseMouseZoomDirection: self.prefs.reverseMouseZoomDir,
                    fusionStyleOrbit: self.prefs.fusionOrbit,
                    setPivotWithLeftMouseButton: self.prefs.clickToSetCOI,
                    zoomTowardPivot: self.prefs.zoomTowardsPivot,
                    viewCubeActsOnPivot: self.prefs.alwaysUsePivot,
                    showViewCube: self.prefs.viewCube,
                    environmentImageVisible: self.prefs.envMapBackground,
                    displayEdges: self.prefs.edgeRendering,
                    displayPoints: self.prefs.pointRendering,
                    displayLines: self.prefs.lineRendering,
                    ghostHiddenObjects: self.prefs.ghosting,
                    groundReflection: self.prefs.groundReflection,
                    groundShadow: self.prefs.groundShadow,
                    ambientShadows: self.prefs.ambientShadows,
                    antiAliasing: self.prefs.antialiasing,
                    progressiveModelDisplay: self.prefs.progressiveRendering,
                    smoothNavigation: self.prefs.optimizeNavigation
                };
                logger.track(settingOptionsStatus);
            }
        },30000));

    };


    Viewer3D.prototype.trackADPExtensionsLoaded = function () {
        var self = this;
        var extensionList = {};
        extensionList.category = "loaded_extensions";
        this.trackADPTimer.push(setTimeout(function () {
           if(self.loadedExtensions) {
               for (var extensionId in self.loadedExtensions) {
                   extensionList[extensionId] = extensionId;
               }
           }
           logger.track(extensionList);
        },30000));
    };

    Viewer3D.prototype.activateDefaultNavigationTools = function(is2d) {
        if (isNodeJS())
            return;
        var defaultNavTool = (is2d ? "pan" : "orbit");
        this.setActiveNavigationTool(defaultNavTool);
        this.setDefaultNavigationTool(defaultNavTool);

        if (this.toolController) {
            this.toolController.enableMouseButtons(true);
            this.toolController.activateTool("gestures");
        }
    };

    Viewer3D.prototype.registerDimensionSpecificHotkeys = function(is2d) {
        if (!this._hotkeyManager)
            return;

        if (is2d) {
            // Remove 3D specific hotkeys
          this._hotkeyManager.popHotkeys("Autodesk.Orbit");
        } else {

            var self = this;

            // Add 3D specific hotkeys
            // Orbit
            var previousTool;
            var onPress = function() {
                previousTool = self.getActiveNavigationTool();
                return self.setActiveNavigationTool("orbit");
            };
            var onRelease = function() {
                return self.setActiveNavigationTool(previousTool);
            };
            var hotkeys = [
                {
                    keycodes: [KeyCode.ALT],
                    onPress: onPress,
                    onRelease: onRelease
                }];
          this._hotkeyManager.pushHotkeys("Autodesk.Orbit", hotkeys, {tryUntilSuccess: true});
        }
    };

    Viewer3D.prototype.onModelAdded = function(model, preserveTools) {

        var models = this.impl.modelQueue().getModels();
        var isFirstModel = (models.length === 1);

        if (isFirstModel) {
            if (model.is2d()) {
                this.activateLayerState("Initial");
            }

            //OK, pay attention here:
            //The code here is a mess in order to make "freeorbit" as default navigation
            //unit test load the initial view correctly.
            //First, we call setActiveNavigationTool() with blank, which will make
            //"freeorbit" the active tool. This will trigger a HACK in Viewer3dImpl.setViewFromCamera
            //which checks for that and uses a different initialization code path of the initial view.
            //Only then, after this happens, we call activateDefaultNavigationTools(), which seems to
            //override the name of the active tool somehow from "freeorbit" to "orbit"
            //and would prevent the camera from initializing correctly.
            //Ideally, the "freeorbit" hack would be removed from Viewer3dImpl and moved here, but this is
            //beyond the scope of the Forge-Fluent merge at this point.

            if (isFirstModel && !model.getData().loadOptions.preserveView) {
                this.setActiveNavigationTool();
                this.impl.setViewFromFile(model, true);
                this.toolController.recordHomeView();
            }

            this.registerDimensionSpecificHotkeys(model.is2d());
            if (!preserveTools)
                this.activateDefaultNavigationTools(model.is2d());

            this.navigation.setIs2D(this.impl.modelQueue().areAll2D());

            // For leaflet, restrict 2D navigation, so that we cannot zoom/pan away from the image
            // and stop zoom-in when reaching max resolution.
            var modelData = model.getData();
            if (modelData.isLeaflet) {
                this.navigation.setConstraints2D(modelData.bbox, modelData.maxPixelPerUnit);
            }
            else {
                // If it is not a leaflet model, clear constrain 2d.
                // Otherwise, it will leak to the next model that viewer could open up.
                this.navigation.setConstraints2D();
            }
        }
    };

    /**
     * Loads a model into the viewer. Can be used sequentially to load multiple 3D models into the same scene.
     * 
     * @param {string} url - The url to the model.
     * @param {object} [options] - Dictionary of options.
     * @param {Autodesk.Viewing.FileLoader} [options.fileLoader] - The file loader to use for this url. Required for unsupported file types.
     * @param {object} [options.loadOptions] - May contain params that are specific for certain loaders/filetypes.
     * @param {string} [options.sharedPropertyDbPath] - Optional path to shared property database.
     * @param {string} [options.ids] - A list of object IDs to load.
     * @param {bool}   [options.loadAsHidden] - By default, a new model is instantly shown and triggers viewer refreshes
     *                                          during loading. Setting this option avoids that. The model can then be
     *                                          shown later by calling showModel().
     * @param {string} [options.modelNameOverride] - Allows host application to override model name used in UI.
     * @param {Autodesk.Viewing.Viewer3D~onLoadModelSuccess} [onSuccessCallback] - A method that gets called when model's metadata loading is done
     * and geometry streaming starts.
     * @param {Autodesk.Viewing.Viewer3D~onLoadModelFailure} [onErrorCallback] - A method that gets called when loading fails.
     * 
     * @alias Autodesk.Viewing.Viewer3D#loadModel
     */
    Viewer3D.prototype.loadModel = async function(url, options, onSuccessCallback, onErrorCallback, onWorkerStart)
    {
        var self = this;

        options = options || {};

        if (typeof options.skipPropertyDb === "undefined") {
            var skipParam = getParameterByName("skipPropertyDb") || "";
            options.skipPropertyDb = skipParam === "true" ? true : (skipParam === "false" ? false : undefined);
        }

        var loaderInstance;
        function onDone( error, model ) {
            self.impl._removeLoadingFile(loaderInstance);
            if (error) {
                onError( error.code, error.msg, error.args );
                return;
            }

            if (options.loadAsHidden) {
                self.impl.modelQueue().addHiddenModel(model);
            } else {
                self.impl.addModel(model);
            }

            if (model.loader && model.loader.notifiesFirstPixel) {
                // Remove loading spinner after at least a part of the model has made it to the screen
                // At this point, only the model root (aka: just data, no graphics) has been received. 
                self.addEventListener(et.RENDER_FIRST_PIXEL, function(){
                    self._loadingSpinner.hide();
                }, {once: true});
            } else {
                self._loadingSpinner.hide();
            }

            if (onSuccessCallback) {
                onSuccessCallback(model);
            }
        }

        function onError( errorCode, errorMessage, errorArgs ) {
            self._loadingSpinner.hide();
            if (onErrorCallback)
                onErrorCallback( errorCode, errorMessage, errorArgs );
        }

        var match = url.toLowerCase().match(/\.([a-z0-9]+)(\?|$)/),
            fileExtension = match ? match[1] : null;

        // Allow loader extension to be in the options block or the known extensions
        var loaderExtension = (this.config && this.config.loaderExtensions && this.config.loaderExtensions[fileExtension]) ||
            (fileExtension in loaderExtensions && loaderExtensions[fileExtension]);
        // If the loader is in an extension and the extension isn't loaded, then load it.
        if (loaderExtension && !this.isExtensionLoaded(loaderExtension)) {
            // The extension registers the loader for the file type 
            await this.loadExtension(loaderExtension);
        }

        var loader;
        if (options && options.fileLoader) {
            loader = options.fileLoader;
        } else {
            loader = FileLoaderManager.getFileLoaderForExtension(fileExtension);
        }

        // if there's no loader, don't try to create it and cause an error.
        if (!loader) {

            logger.error("File extension not supported:" + fileExtension, errorCodeString(ErrorCodes.UNSUPORTED_FILE_EXTENSION));
            onError(ErrorCodes.UNSUPORTED_FILE_EXTENSION, "File extension not supported", 0, fileExtension);
            return false;
        }

        //Run some heuristics to adapt the viewing experience to the model we are about to display
        this.setLoadHeuristics(options);

        // Add spinner for first model
        if (!this.impl.hasModels()) {
            this._loadingSpinner.show();
        }

        loaderInstance = new loader(this.impl, this.config);
        var returnValue = loaderInstance.loadFile(url, options, onDone, onWorkerStart);

        //If we know that the model is going to use a specific light preset, trigger the environment map
        //change as early as possible (i.e. not on addModel ) in order to avoid a flash from the old map to the new
        //one that happens if the change is delayed until addModel.
        if (!isNodeJS() && options.isAEC && loaderInstance.is3d())
            this.impl.setLightPresetForAec();

        this.impl.setRenderingPrefsFor2D(!loaderInstance.is3d());

        return returnValue;
    };


    //Temporarily exported until Fluent adjusts to using loadDocumentNode
    export function waitForCompleteLoad(viewer, options) {

        return new Promise((resolve, reject) => {

            var loadedParts = 0;

            function endPromiseMaybe(model) {
                if (loadedParts === 2) {
                    resolve({viewer:viewer, model: model});
                }
            }

            //We have to wait for two or three things to happen in order to
            //say that the model is fully loaded. (1) the geometry,
            //(2) the material textures and (3) optionally the property database
            //The logic below hooks into various event callbacks to make this happen.

            //Callback on geometry load complete
            function gcb(e) {

                //Make sure we handle only the model we want this callback to handle
                //as we load multiple sheets into the same viewer context
                if (e.model.getData().loadOptions.bubbleNode !== options.bubbleNode)
                    return;


                viewer.removeEventListener(et.GEOMETRY_LOADED_EVENT, gcb);

                loadedParts++;

                //Call getProperties to make sure property db is loaded,
                //then continue with hashing
                if (options.skipPropertyDb) {
                    endPromiseMaybe(e.model);
                } else {
                    e.model.getProperties(
                        1,
                        success => endPromiseMaybe(e.model),
                        error => reject(error)
                    );
                }

            }
            viewer.addEventListener(et.GEOMETRY_LOADED_EVENT, gcb);


            function tcb(e) {

                //Make sure we handle only the model we want this callback to handle
                //as we load multiple sheets into the same viewer context
                if (e.model.getData().loadOptions.bubbleNode !== options.bubbleNode)
                    return;

                viewer.removeEventListener(et.TEXTURES_LOADED_EVENT, tcb);

                loadedParts++;

                endPromiseMaybe(e.model);
            }
            viewer.addEventListener(et.TEXTURES_LOADED_EVENT, tcb);
        });
    }

    /**
     * Unloads the specified model.
     * Reference {@link Autodesk.Viewing.Viewer3D#hideModel} to hide the model.
     * @param {Autodesk.Viewing.Model} model - The model to unload.
     * 
     * @alias Autodesk.Viewing.Viewer3D#unloadModel
     */
    Viewer3D.prototype.unloadModel = function(model) {
        if (!model) {
            model = this.model;
        }
        
        this.impl.unloadModel(model);
    }


    /**
     * 
     * @param {Autodesk.Viewing.Document} avDocument - The Document instance, which owns the model being loaded
     * @param {Autodesk.Viewing.BubbleNode} manifestNode - The specific manifest node to load (within the Document) 
     * @param {ViewerConfig} [options] - Options to pass to {@link Autodesk.Viewing.Viewer3D#loadModel}. Will be initialized internally if not specified.
     *                                   The options object will be augmented by automatically determined load parameters.
     * 
     * @alias Autodesk.Viewing.Viewer3D#loadDocumentNode
     */
    Viewer3D.prototype.loadDocumentNode = function(avDocument, manifestNode, options) {

        var bubbleNode = manifestNode;

        //var modelRootUrl = bubbleNode.getViewableRootPath();
        var leafletOptions = {};
        var modelRootUrl = avDocument.getViewableUrn(bubbleNode, leafletOptions);
        var sharedDbPath = bubbleNode.findPropertyDbPath();
        
        //PDF library wants absolute path in all cases
        //TODO: move PDF's logic into getFullPath as well, so that all cases are handled there.
        if (leafletOptions.isPdf && getEnv() !== 'Local') {
            modelRootUrl = endpoint.getItemApi(endpoint.getApiEndpoint(), modelRootUrl, 'derivativeV2');
        } else {
            modelRootUrl = avDocument.getFullPath(modelRootUrl);
            sharedDbPath = avDocument.getFullPath(sharedDbPath);
        }

        var loadOptions = {
            sharedPropertyDbPath: sharedDbPath,
            acmSessionId: avDocument.acmSessionId,
            bubbleNode: bubbleNode,
            // Auto-center model by default. This avoids shaking geometry / z-issues for georeferenced models.
            // Note: If you display multiple models at once, you may have change it to true and set a fixed globalOffset to align them properly.
            applyRefPoint: false, 
            loadOptions: leafletOptions,
            page: leafletOptions.page || 1
        };

        options = options || {};

        for (var p in loadOptions) {
            if (!options.hasOwnProperty(p))
                options[p] = loadOptions[p];
        }
    
        if (!options.keepCurrentModels) {
            let _conf = this.config;
            this.tearDown();
            this.setUp(_conf);
        }

        var geomNode = bubbleNode.findParentGeom2Dor3D();
        var modelExtensions = geomNode.extensions();
        if (Array.isArray(modelExtensions)) {
            modelExtensions.forEach((extId)=>{
                this.loadExtension(extId, this.config);
            })
        }

        var that = this;
        var res = leafletOptions.isPdf ? this.loadExtension("Autodesk.PDF") : Promise.resolve();

        return res.then(() => new Promise(function(resolve, reject) {
            that.loadModel(modelRootUrl, options,
                function onSuccess(model){
                    if (bubbleNode.type() === 'view') {
                        that.setView(bubbleNode, {skipTransition: true});
                    }
                    resolve(model); 
                }, 
                function onFailure(error){ reject(error); }
            )
        }));
    };

    // Unloads a model previously loaded by loadDocumentNode(). 
    //  @param {BubbleNode} manifestNode
    //  @returns {bool} true on success
    Viewer3D.prototype.unloadDocumentNode = function(manifestNode) {

        // If model is in memory, just unload it.
        let model = this.impl.findModel(manifestNode, true);
        if (model) {
            this.impl.unloadModel(model);
            return true;
        }

        // If there are no loaders in progress, we are done here.
        if (!this.impl.loaders) {
            return false;
        }

        // Check if any loader is currently loading the model root
        //
        // Note: Cancelling loading only works for loaders that properly supports it. (so far: Otg, F2D, Svf)
        //       For this, a loader must:
        //         - Provide the load options in loader.options
        //         - Properly handle the case that dtor is called before model root is loaded, e.g. avoiding any further callback invocation
        for (let i=0; i<this.impl.loaders.length; i++) {
        
            // Check if the loader's docNode matches with the one we want to unload
            let loader     = this.impl.loaders[i];
            let loaderNode = loader.options && loader.options.bubbleNode;
            if (loaderNode === manifestNode) {

                // Loader found - stop it
                loader.dtor();
                this.impl.loaders.splice(i, 1);
                return true;
            }
        }

        // Nothing found to unload. Either the model was never loaded, was already unloaded, or is in progress and the loader does not support 
        // to cancel loading 
        return false;
    }

    /**
     * Returns the dimensions of the WebGL canvas.
     * 
     * @returns {object} Client rectangle bounds object { width:Number, height: Number }
     * 
     * @alias Autodesk.Viewing.Viewer3D#getDimensions
     */
    Viewer3D.prototype.getDimensions = function() {
        if (this.container) {
            // NB: Getting dimensions of the client container instead of the container.
            //     At least in IE11, getting dimensions on the dynamically created
            //     child of the dynamically created parent returns a 0 height.
            var rect = {};
            if (this.getScreenMode() === ScreenMode.kFullScreen) {
                rect.width = screen.width;
                rect.height = screen.height;
            } else {
                rect = this.container.getBoundingClientRect();
            }

            return {
                width: rect.width,
                height: rect.height
            };
        }

        return null;
    };


    /**
     * Resizes the viewer. Required when wrapping div changes dimensions due to CSS changes.
     * 
     * @alias Autodesk.Viewing.Viewer3D#resize
     */
    Viewer3D.prototype.resize = function()
    {
        if (this.container.clientWidth > 0 && this.container.clientHeight > 0) {
            this.impl.resize(this.container.clientWidth, this.container.clientHeight);
        }
    };

    /**
     * @returns {Autodesk.Viewing.HotkeyManager} The hotkey manager.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getHotkeyManager
     */
    Viewer3D.prototype.getHotkeyManager = function()
    {
        return this._hotkeyManager;
    };

    /**
     * Gets the camera so it can be modified by the client.
     * @returns {THREE.Camera} The active camera.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getCamera
     */
    Viewer3D.prototype.getCamera = function()
    {
        return this.impl.camera;
    };

    /**
     * Gets the view state as a plain object.
     * A viewer state contains data for the viewport, selection and isolation,  
     *
     * @tutorial viewer_state
     * 
     * @param {object} [filter] - Specifies which viewer values to get.
     * @returns {object} Viewer state.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getState
     */
    Viewer3D.prototype.getState = function( filter ) {
        return this.viewerState.getState(filter);
    };

    /**
     * Restores the viewer state from a given object.
     * 
     * @tutorial viewer_state
     * 
     * @param {Object} viewerState
     * @param {Object} [filter] - Similar in structure to viewerState used to filter out values
     * that should not be restored.
     * @param {boolean} [immediate] - Whether the new view is applied with (true) or without transition (false).
     * @returns {boolean} True if restore operation was successful.
     * 
     * @alias Autodesk.Viewing.Viewer3D#restoreState
     * @fires Autodesk.Viewing#VIEWER_STATE_RESTORED_EVENT
     */
    Viewer3D.prototype.restoreState = function (viewerState, filter, immediate)  {
        var success = this.viewerState.restoreState(viewerState, filter, immediate);
        if (success) {
            this.dispatchEvent({ type: et.VIEWER_STATE_RESTORED_EVENT, value: success });
        }
        return success;
    };
    
    /**
     * Loads a view specified in the Manifest JSON.
     * For 3D models it will use the camera values.
     * For 2D models it will use the viewBox values.
     * 
     * @param {BubbleNode} viewNode 
     * @param {Object} [options] 
     * @param {boolean} [options.skipTransition=false] - true to apply instanstly instead of lerping. 
     * @param {boolean} [options.useExactCamera=true] - whether any up vector adjustment is to be done (to keep head up view)
     * 
     * @returns {boolean} true, if the view is applied.
     */
    Viewer3D.prototype.setView = function(viewNode, options) {

        if (!this.model)
            return false;

        if (!viewNode || !(viewNode instanceof BubbleNode)) 
            return false;
        
        let skipTransition = options ? options.skipTransition : false;
        let useExactCamera = options ? options.useExactCamera : true;

        // 2D models
        if (viewNode.getViewBox()) {
            this.setViewFromViewBox(viewNode.getViewBox(), viewNode.name(), skipTransition);
            this.dispatchEvent({type: et.SET_VIEW_EVENT, view: viewNode});
            return true;
        }

        // 3D models
        var camera = this.getCameraFromViewArray(viewNode.data.camera);
        if (camera) {
            this.impl.setViewFromCamera(camera, skipTransition, useExactCamera);
        } else {
            return false;
        }

        // Camera loaded, any section planes or boxes?
        const sp = viewNode.data.sectionPlane;
        const sb = viewNode.data.sectionBox;
        const sbt = viewNode.data.sectionBoxTransform;
        var globalOffset = this.model.getData().globalOffset;
     
        if (sp) {

            // Variable `sp` contains one or more planes. Each plane is
            // defined by 4 numbers, so sp will have either 4, 8, 12, etc values,
            // for 1, 2, 3, etc planes.

            let cutplanes = [];
            for (var i=0; i<sp.length; i+=4) {
                var plane = new THREE.Vector4(sp[i+0], sp[i+1], sp[i+2], sp[i+3]);
                if (globalOffset) {
                    plane.w += THREE.Vector3.prototype.dot.call( globalOffset, new THREE.Vector3(sp[i+0], sp[i+1], sp[i+2]) );
                }
                cutplanes.push(plane);
            }
            this.impl.setCutPlaneSet('__set_view', cutplanes);

        } else if (sb && sbt) {

            const sbTransformMatrix = new THREE.Matrix4().fromArray([
                sbt[0], sbt[1],   sbt[2],   sbt[3],
                sbt[4], sbt[5],   sbt[6],   sbt[7],
                sbt[8], sbt[9],   sbt[10],  sbt[11],
                sbt[12], sbt[13], sbt[14],  sbt[15]
            ]);

            if (globalOffset) {
                var offset = new THREE.Matrix4().makeTranslation(
                    -globalOffset.x, 
                    -globalOffset.y, 
                    -globalOffset.z
                );
                sbTransformMatrix.multiply(offset);
            }

            let min = new THREE.Vector3(sb[0], sb[1], sb[2]);
            let max = new THREE.Vector3(sb[3], sb[4], sb[5]);
            let box = new THREE.Box3(min, max);
            box.applyMatrix4(sbTransformMatrix);

            const planes = SceneMath.box2CutPlanes(box);
            this.impl.setCutPlaneSet('__set_view', planes);

        } else {

            this.impl.setCutPlaneSet('__set_view', undefined);
        }

        this.dispatchEvent({type: et.SET_VIEW_EVENT, view: viewNode});
        return true;
    };

    /**
     * Sets the view from an array of parameters.
     * @param {array} params - View parameters:
     * - position-x
     * - position-y
     * - position-z
     * - target-x
     * - target-y
     * - target-z
     * - up-x
     * - up-y
     * - up-z
     * - aspect
     * - fov (radians)
     * - orthoScale
     * - isPerspective (0=perspective, 1=ortho)
     */
    Viewer3D.prototype.setViewFromArray = function(params)
    {
        //TODO: It might be best to get rid of the setViewFromArray API as it's not
        //very descriptive, and move the params->camera conversion to the bubble-reading
        //logic elsewhere.
        var camera = this.getCameraFromViewArray(params);
        this.impl.setViewFromCamera(camera, false, true);
    };

    /**
     * Returns an object representing a Camera from an unintuitive array of number.
     * 
     * @param {Number[]} params - Array with 13 elements describing different aspects of a camera.
     * @returns {Object|null} - Camera object, or null if argument is invalid or missing.
     */
    Viewer3D.prototype.getCameraFromViewArray = function(params) {
        var offset = this.model ? this.model.getData().globalOffset : undefined;
        return BubbleNode.readCameraFromArray(params, offset);
    };

    /**
     * Returns an Array of values that could be inserted back into
     * a manifest to represent a view.
     */
    Viewer3D.prototype.getViewArrayFromCamera = function() {
        var off = this.model ? this.model.getData().globalOffset : { x:0, y:0, z:0 };
        return this.impl.getViewArrayFromCamera(off);
    };

    /**
     * Sets the view from an array representing a view box.
     *
     * Not applicable to 3D.
     *
     * @param {Number[]} viewbox - View parameters:
     * - min-x
     * - min-y
     * - max-x
     * - max-y
     * @param {string} [name] - Optional named view name to also set the layer visibility state
     * associated with this view.
     * @param {boolean} [skipTransition] - true to apply instanstly instead of lerping. 
     * 
     * @alias Autodesk.Viewing.Viewer3D#setViewFromViewBox
     */
    Viewer3D.prototype.setViewFromViewBox = function (viewbox, name, skipTransition)
    {
        var model = this.model;

        if( model && !model.is2d() )
        {
            logger.warn("Viewer3D.setViewFromViewBox is not applicable to 3D");
            return;
        }

        //set the layer state if any
        //It's annoying to search the views and states as arrays,
        //but this is the only place we do this, so converting them
        //to hashmaps is not necessary (yet).
        if (name && name.length) {
            var metadata = model.getData().metadata;
            var views = metadata.views;

            var i;
            for (i=0; i<views.length; i++) {
                if (views[i].name == name)
                    break;
            }

            if (i < views.length) {
                var state_name = views[i].layer_state;
                if (state_name)
                    this.activateLayerState(state_name);
            }
        }

        //Finally set the camera
        this.impl.setViewFromViewBox(this.model, viewbox, name, skipTransition);
    };

    /**
     * Changes the active layer state.
     * Layes is a feature usually available on 2D models and some 3D models. 
     *
     * @param {string} stateName - Name of the layer state to activate.
     * 
     * @alias Autodesk.Viewing.Viewer3D#activateLayerState
     * 
     * @see Autodesk.Viewing.Viewer3D#getLayerStates
     * @fires  Autodesk.Viewing#LAYER_VISIBILITY_CHANGED_EVENT
     */
    Viewer3D.prototype.activateLayerState = function(stateName)
    {
        this.impl.layers.activateLayerState(stateName);
        this.dispatchEvent({type: et.LAYER_VISIBILITY_CHANGED_EVENT});
    };

    /**
     * Returns information for each layer state: name, description, active.
     * Activate a state through {@link Autodesk.Viewing.Viewer3D#activateLayerState}.
     * @returns {array}
     * 
     * @alias Autodesk.Viewing.Viewer3D#getLayerStates
     */
    Viewer3D.prototype.getLayerStates = function () {

        return this.impl.layers.getLayerStates();
    };

    /**
     * Sets the view using the default view in the source file.
     * 
     * @param {Autodesk.Viewing.Model} [model] - The model, defaults to the loaded model.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setViewFromFile
     */
    Viewer3D.prototype.setViewFromFile = function(model)
    {
        this.setActiveNavigationTool();
        this.impl.setViewFromFile(model || this.model);
    };

    /**
     * Gets the properties for an ID.
     * 
     * @param {number} dbid - The database identifier.
     * @param {Callbacks#onPropertiesSuccess} [onSuccessCallback] - Callback for when the properties are fetched.
     * @param {Callbacks#onGenericError} [onErrorCallback] - Callback for when the properties are not found or another error occurs.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getProperties
     */
    Viewer3D.prototype.getProperties = function(dbid, onSuccessCallback, onErrorCallback)
    {
        if (this.model) {
            this.model.getProperties(dbid, onSuccessCallback, onErrorCallback);
        }
        else {
            if (onErrorCallback)
                onErrorCallback(ErrorCodes.BAD_DATA, "Properties failed to load since model does not exist");
        }
    };

    /**
     * Gets the viewer model object tree. Once the tree is received it will invoke the specified callback function.
     *
     * You can use the model object tree to get information about items in the model.  The tree is made up
     * of nodes, which correspond to model components such as assemblies or parts.
     * 
     * @param {Callbacks#onObjectTreeSuccess} [onSuccessCallback] - Success callback invoked once the object tree is available.
     * @param {Callbacks#onGenericError} [onErrorCallback] - Error callback invoked when the object tree is not found available.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getObjectTree
     */
    Viewer3D.prototype.getObjectTree = function(onSuccessCallback, onErrorCallback)
    {
        if (this.model) {
            this.model.getObjectTree(onSuccessCallback, onErrorCallback);
        }
        else {
            if (onErrorCallback)
                onErrorCallback(ErrorCodes.BAD_DATA, "ObjectTree failed to load since model does not exist");
        }
    };

    /**
     * Sets the click behavior on the canvas to follow config.
     * This is used to change the behavior of events such as selection or Center-of-Interest changed.
     * @example
     *  {
     *       "click": {
     *           "onObject": [ACTIONS],
     *           "offObject": [ACTIONS]
     *       },
     *       "clickCtrl": {
     *           "onObject": [ACTIONS],
     *           "offObject": [ACTIONS]
     *       },
     *       "clickShift": {
     *           ...
     *       },
     *       "clickCtrlShift": {
     *           ...
     *       },
     *       "disableSpinner": BOOLEAN
     *       "disableMouseWheel": BOOLEAN,
     *       "disableTwoFingerSwipe": BOOLEAN
     *  }
     *
     * Actions can be any of the following:
     * - "selectOnly"
     * - "selectToggle"
     * - "deselectAll"
     * - "isolate"
     * - "showAll"
     * - "setCOI"
     * - "focus"
     * - "hide"
     * @param {object} config - Parameter object that meets the above layout.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setCanvasClickBehavior
     */
    Viewer3D.prototype.setCanvasClickBehavior = function(config)
    {
        if (this.impl.controls.hasOwnProperty("setClickBehavior"))
            this.impl.controls.setClickBehavior(config);

        if( this.clickHandler )
            this.clickHandler.setClickBehavior(config);

        if (config && config.disableMouseWheel) {
            this.toolController.setMouseWheelInputEnabled(false);
        }

        if (config && config.disableTwoFingerSwipe) {
            var gestureHandler = this.toolController.getTool("gestures");
            if (gestureHandler) {
                gestureHandler.disableTwoFingerSwipe();
            }
        }
    };

    /**
     * Searches the elements for the given text. When the search is complete,
     * the callback onResultsReturned(idArray) is invoked.
     * 
     * @param {string} text - The search term (not case sensitive).
     * @param {Callbacks#onSearchSuccess} onSuccessCallback - Invoked when the search results are ready.
     * @param {Callbacks#onGenericError} onErrorCallback - Invoke when an error occured during search.
     * @param {string[]} [attributeNames] - Restricts search to specific attribute names.
     * 
     * @alias Autodesk.Viewing.Viewer3D#search
     */
    Viewer3D.prototype.search = function(text, onSuccessCallback, onErrorCallback, attributeNames)
    {
        this.searchText = text;

        if (this.model) {
            this.model.search(text, onSuccessCallback, onErrorCallback, attributeNames);
        }
        else {
            if (onErrorCallback)
                onErrorCallback(ErrorCodes.BAD_DATA, "Search failed since model does not exist");
        }
    };

    /**
     * Returns an array of the IDs of the currently hidden nodes.
     * When isolation is in place, there are no hidden nodes returned because
     * all nodes that are not isolated are considered hidden.
     *
     * @returns {number[]} Array of nodes that are currently hidden, when no isolation is in place.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getHiddenNodes
     */
    Viewer3D.prototype.getHiddenNodes = function () {
        return this.impl.visibilityManager.getHiddenNodes();
    };

    /**
     * Returns an array of the IDs of the currently isolated nodes.
     *
     * Not yet implemented for 2D.
     *
     * @returns {number[]} Array of nodes that are currently isolated.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getIsolatedNodes
     */
    Viewer3D.prototype.getIsolatedNodes = function () {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getIsolatedNodes is not yet implemented for 2D");
            return [];
        }

        return this.impl.visibilityManager.getIsolatedNodes();
    };

    /**
     * Isolates one of many sub-elements. You can pass in a node or an array of nodes to isolate.
     * Pass in null to reset isolation.
     *
     * @param {number[]|number} node - A node ID or array of node IDs from the model tree {@link BaseViewer#getObjectTree}.
     * @param {Autodesk.Viewing.Model} [model] - the model that contains the node id. Defaults to the first loaded model.
     * 
     * @alias Autodesk.Viewing.Viewer3D#isolate
     */
    Viewer3D.prototype.isolate = function(node, model)
    {
        model = model || this.model;
        if (!model) {
            return;
        }

        var data = model.getData();
        this.impl.visibilityManager.isolate(node, model);
    };


    /**
     * Sets the background colors, which will be used to create a gradient.
     * Values are in the range [0..255]
     * 
     * @param {number} red
     * @param {number} green
     * @param {number} blue
     * @param {number} red2
     * @param {number} green2
     * @param {number} blue2
     * 
     * @alias Autodesk.Viewing.Viewer3D#setBackgroundColor
     */
    Viewer3D.prototype.setBackgroundColor = function(red, green, blue, red2, green2, blue2)
    {
        this.impl.setClearColors(red, green, blue, red2, green2, blue2);
    };

    /**
     * Toggles the selection for a given dbid.
     * If it was unselected, it is selected.
     * If it was selected, it is unselected.
     *
     * Currently three ways of node selection are supported:
     * - Autodesk.Viewing.SelectionMode.MIXED
     *   - Leaf nodes are selected using the overlayed selection type, and inner nodes are selected using regular selection type.
     * - Autodesk.Viewing.SelectionMode.REGULAR
     *   - Nodes are tinted with the selection color.
     * - Autodesk.Viewing.SelectionMode.OVERLAYED
     *   - Nodes are tinted with the selection color and shown on top of the not selected geometry.
     *
     * Not yet implemented for 2D.
     *
     * @param {number} dbid
     * @param {Autodesk.Viewing.Model} [model] - the model that contains the dbId. Uses the initial model loaded by default.
     * @param {number} selectionType a member of Autodesk.Viewing.SelectionMode.
     * 
     * @alias Autodesk.Viewing.Viewer3D#toggleSelect
     */
    Viewer3D.prototype.toggleSelect = function(dbid, model, selectionType)
    {
        model = model || this.model;
        if (model && model.is2d())
        {
            // Fails because Model.getNodeById is not supported.
            logger.warn("Viewer3D.toggleSelect is not yet implemented for 2D");
            return;
        }

        this.impl.selector.toggleSelection(dbid, model, selectionType);
    };

     /**
     * Selects the array of ids. You can also pass in a single id instead of an array.
     *
     * Currently three ways of node selection are supported:	
     * - Autodesk.Viewing.SelectionMode.MIXED	
     *   - Leaf nodes are selected using the overlayed selection type, and inner nodes are selected using regular selection type.	
     * - Autodesk.Viewing.SelectionMode.REGULAR	
     *   - Nodes are tinted with the selection color.	
     * - Autodesk.Viewing.SelectionMode.OVERLAYED	
     *   - Nodes are tinted with the selection color and shown on top of the not selected geometry.
     * 
     * @param {number[]|number} dbids - element or array of elements to select.
     * @param {Autodesk.Viewing.Model} [model] - the model instance containing the ids.
     * @param {number} [selectionType] - a member of `Autodesk.Viewing.SelectionMode`.
     * 
     * @alias Autodesk.Viewing.Viewer3D#select
     */
    Viewer3D.prototype.select = function(dbids, model, selectionType)
    {
        if (typeof dbids === "number") {
            dbids = [dbids];
        }

        model = model || this.model;
        this.impl.selector.setSelection(dbids, model, selectionType);
    };


    /**
     * Clears the selection.
     * @alias Autodesk.Viewing.Viewer3D#clearSelection
     */
    Viewer3D.prototype.clearSelection = function()
    {
        this.impl.selector.clearSelection();
    };

    /**
     * Returns information about the visibility of the current selection.
     * @returns {object} `{hasVisible:boolean, hasHidden:boolean}`
     * 
     * @alias Autodesk.Viewing.Viewer3D#getSelectionVisibility
     */
    Viewer3D.prototype.getSelectionVisibility = function () {
        return this.impl.selector.getSelectionVisibility();
    };

    /**
     * Returns the number of nodes in the current selection.
     * @returns {number}
     * @alias Autodesk.Viewing.Viewer3D#getSelectionCount
     */
    Viewer3D.prototype.getSelectionCount = function () {
        return this.impl.selector.getSelectionLength();
    };

    /**
     * Sets selection granularity mode. Supported values are:
     * - Autodesk.Viewing.SelectionMode.LEAF_OBJECT
     *   - Always select the leaf objects in the hierarchy.
     * - Autodesk.Viewing.SelectionMode.FIRST_OBJECT
     *   - For a given node, selects the first non-composite (layer, collection, model)
     *   on the path from the root to the given node, and all children.
     * - Autodesk.Viewing.SelectionMode.LAST_OBJECT
     *   - For a given node, selects the nearest ancestor composite node and all children.
     *   Selects the input node itself in case there is no composite node in the path to the root node.
     * 
     * @param {number} mode - The selection mode.
     * @alias Autodesk.Viewing.Viewer3D#setSelectionMode
     */
    Viewer3D.prototype.setSelectionMode = function (mode) {
        this.impl.selector.setSelectionMode(mode);
    };


    /**
     * Returns the current selection.
     * @returns {number[]} Array of the IDs of the currently selected nodes.
     * @alias Autodesk.Viewing.Viewer3D#getSelection
     */
    Viewer3D.prototype.getSelection = function () {
        return this.impl.selector.getSelection();
    };

    /**
     * Returns the selected items from all loaded models.
     * @param {function} [callback] - Optional callback to receive enumerated pairs of model and dbId
     * for each selected object. If no callback is given, an array of objects is returned.
     * @returns {object[]} An array of objects with a model and selectionSet properties for each model
     * that has selected items in the scene.
     * @alias Autodesk.Viewing.Viewer3D#getAggregateSelection
     */
    Viewer3D.prototype.getAggregateSelection = function(callback) {
        var res = this.impl.selector.getAggregateSelection();

        if (callback) {
            for (var i=0; i<res.length; i++) {
                for (var j=0; j<res[i].selection.length; j++) {
                    callback(res[i].model, res[i].selection[j]);
                }
            }
        }

        return res;
    };

    /**
     * Returns the isolated items from all loaded models.
     * 
     * @returns {object[]} An array of objects with a `model` and the isolated `ids` in that model.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getAggregateIsolation
     */
    Viewer3D.prototype.getAggregateIsolation = function() {

        var res = this.impl.visibilityManager.getAggregateIsolatedNodes();
        return res;
    };

    /**
     * Returns the hidden nodes for all loaded models.
     * 
     * @returns {object[]} An array of objects with a `model` and the hidden `ids` in that model. 
     * 
     * @alias Autodesk.Viewing.Viewer3D#getAggregateHiddenNodes
     */
    Viewer3D.prototype.getAggregateHiddenNodes = function() {

        var res = this.impl.visibilityManager.getAggregateHiddenNodes();
        return res;
    };

    /**
     * Ensures the passed in dbid / ids are hidden.
     *
     * @param {number[]|number} node - An array of dbids or just an id
     * @param {Autodesk.Viewing.Model} [model] - The model that contains the dbId. By default uses the initial model loaded into the scene. 
     * 
     * @alias Autodesk.Viewing.Viewer3D#hide
     */
    Viewer3D.prototype.hide = function(node, model)
    {
        model = model || this.model;
        this.impl.visibilityManager.hide(node, model);
    };

    /**
     * Ensures the passed in dbid / ids are shown.
     *
     * @param {number[]|number} node
     * @param {Autodesk.Viewing.Model} [model] - The model that contains the dbId. By default uses the initial model loaded into the scene. 
     * 
     * @alias Autodesk.Viewing.Viewer3D#show
     */
    Viewer3D.prototype.show = function(node, model)
    {
        model = model || this.model;
        this.impl.visibilityManager.show(node, model);
    };

    /**
     * Ensures everything is visible. Clears all node isolation (3D) and turns on all layers (2D).
     * 
     * @alias Autodesk.Viewing.Viewer3D#showAll
     */
    Viewer3D.prototype.showAll = function()
    {
        this.impl.visibilityManager.aggregateIsolate([]);
        this.impl.layers.showAllLayers();
    };

    /**
     * Toggles the visibility of the given node.
     *
     * Not yet implemented for 2D.
     *
     * @param {number} dbId - the object's identifier.
     * @param {Autodesk.Viewing.Model} [model] - the model that contains the dbId. By default uses the initial model loaded into the scene.
     * 
     * @alias Autodesk.Viewing.Viewer3D#toggleVisibility
     */
    Viewer3D.prototype.toggleVisibility = function(dbId, model)
    {
        this.impl.visibilityManager.toggleVisibility(dbId, model);
    };

    /**
     * Returns true if every node is visible.
     * @returns {boolean}
     * 
     * @alias Autodesk.Viewing.Viewer3D#areAllVisible
     */
    Viewer3D.prototype.areAllVisible = function() {
        return this.impl.isWholeModelVisible(this.model);
    };

    /**
     * Returns true if the specified node is visible.
     * The model argument is required only when in multi-model scenarios.
     *
     * @param {number} nodeId - Geometry node to check if visible.
     * @param {Autodesk.Viewing.Model} [model] - The model that contains the specified ``nodeId``.
     *
     * @returns {boolean}
     * 
     * @alias Autodesk.Viewing.Viewer3D#isNodeVisible
     */
    Viewer3D.prototype.isNodeVisible = function(nodeId, model) {
        model = model || this.model;
        return this.impl.isNodeVisible(nodeId, model);
    };

    /**
     * Explodes the model from the center of gravity.
     *
     * Not applicable to 2D.
     *
     * @param {number} scale - A value from 0.0-1.0 to indicate how much to explode.
     * 
     * @alias Autodesk.Viewing.Viewer3D#explode
     */
    Viewer3D.prototype.explode = function( scale)
    {
        if (!this.model)
            return;

        if (this.model.is2d())
        {
            logger.warn("Viewer3D.explode is not applicable to 2D");
            return;
        }

        var exploded = this.impl.explode(scale);
        if (exploded) {
            logger.track({ name: 'explode_count', aggregate: 'count' });
        }
    };

    /**
     * Returns the explode scale.
     *
     * Not applicable to 2D.
     *
     * @returns {number} - A value from 0.0-1.0 indicating how exploded the model is.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getExplodeScale
     */
    Viewer3D.prototype.getExplodeScale = function()
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getExplodeScale is not applicable to 2D");
            return 0;
        }

        return this.impl.getExplodeScale();
    };


    /**
     * Enables or disables the high quality rendering settings.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} useSAO - True or false to enable screen space ambient occlusion.
     * @param {boolean} useFXAA - True or false to enable fast approximate anti-aliasing.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setQualityLevel
     */
    Viewer3D.prototype.setQualityLevel = function(useSAO, useFXAA)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setQualityLevel is not applicable to 2D");
            return;
        }

        this.prefs.set('ambientShadows', useSAO);
        this.prefs.set('antialiasing', useFXAA);
        this.impl.togglePostProcess(useSAO, useFXAA);
    };


    /**
     * Toggles ghosting during search and isolate.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether ghosting is on or off.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGhosting
     */
    Viewer3D.prototype.setGhosting = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGhosting is not applicable to 2D");
            return;
        }

        this.prefs.set('ghosting', value);
        this.impl.toggleGhosting(value);
    };

    /**
     * Toggles ground shadow.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether shadow is on or off.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundShadow
     */
    Viewer3D.prototype.setGroundShadow = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundShadow is not applicable to 2D");
            return;
        }

        this.prefs.set('groundShadow', value);
        this.impl.toggleGroundShadow(value);
    };

    /**
     * Toggles ground reflection.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether reflection is on or off.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundReflection
     */
    Viewer3D.prototype.setGroundReflection = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundReflection is not applicable to 2D");
            return;
        }

        this.prefs.set('groundReflection', value);
        this.impl.toggleGroundReflection(value);
    };

    /**
     * Toggles environment map for background.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether environment map for background is on or off.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setEnvMapBackground
     */
    Viewer3D.prototype.setEnvMapBackground = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setEnvMapBackground is not applicable to 2D");
            return;
        }

        this.prefs.set('envMapBackground', value);
        this.impl.toggleEnvMapBackground(value);
    };

    /**
     * Toggles first person tool popup.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether first person tool popup is showed or not.
     */
    Viewer3D.prototype.setFirstPersonToolPopup = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setFirstPersonToolPopup is not applicable to 2D");
            return;
        }

        this.prefs.set('firstPersonToolPopup', value);
    };

    /**
     * Returns the state of First Person Walk tool popup
     *
     * Not applicable to 2D.
     *
     * @returns {boolean} true if the First Person Walk tool popup appears, false if the First Person Walk tool popup does not appear.
     * 
     */
    Viewer3D.prototype.getFirstPersonToolPopup = function()
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getFirstPersonToolPopup is not applicable to 2D");
            return;
        }

        return this.prefs.firstPersonToolPopup;
    };

    /**
     * Toggles the bimwalk tool popup.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value - Indicates whether first person tool popup is showed or not.
     * 
     */
    Viewer3D.prototype.setBimWalkToolPopup = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setBimWalkToolPopup is not applicable to 2D");
            return;
        }

        this.prefs.set('bimWalkToolPopup', value);
    };


    /**
     * Returns the state of First Person Walk tool popup
     *
     * Not applicable to 2D.
     *
     * @returns {boolean} true if the First Person Walk tool popup appears, false if the First Person Walk tool popup does not appear.
     */
    Viewer3D.prototype.getBimWalkToolPopup = function()
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getBimWalkToolPopup is not applicable to 2D");
            return;
        }

        return this.prefs.bimWalkToolPopup;
    };

    /**
     * Toggles whether progressive rendering is used. Warning: turning progressive rendering off
     * will have serious performance implications.
     * @param {boolean} value whether it is on or off
     * 
     * @alias Autodesk.Viewing.Viewer3D#setProgressiveRendering
     */
    Viewer3D.prototype.setProgressiveRendering = function(value)
    {
        this.prefs.set('progressiveRendering', value);
        this.impl.toggleProgressive(value);
    };

    /**
     * Overrlides line colors in 2D models to render in shades of gray.
     * Applies only to 2D models.
     * 
     * @param {boolean} value 
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGrayscale
     */
    Viewer3D.prototype.setGrayscale = function(value)
    {
        this.prefs.set('grayscale', value);
        this.impl.toggleGrayscale(value);
    };

    /**
     * AutoCAD drawings are commonly displayed with white lines on a black background. Setting reverse swaps (just)
     * these two colors.
     * @param {boolean} value whether it is on or off
     * 
     * @alias Autodesk.Viewing.Viewer3D#setSwapBlackAndWhite
     */
    Viewer3D.prototype.setSwapBlackAndWhite = function(value)
    {
        this.prefs.set('swapBlackAndWhite', value);
        this.impl.toggleSwapBlackAndWhite(value);
    };

    /**
     * Toggles whether the navigation should be optimized for performance. If set
     * to true, anti-aliasing and ambient shadows will be off while navigating.
     *
     * Not applicable to 2D.
     *
     * @param {boolean} value whether it is on or off
     * 
     * @alias Autodesk.Viewing.Viewer3D#setOptimizeNavigation
     */
    Viewer3D.prototype.setOptimizeNavigation = function(value)
    {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setOptimizeNaviation is not applicable to 2D");
            return;
        }

        this.prefs.set('optimizeNavigation', value);
        this.impl.setOptimizeNavigation(value);
    };

    /**
     * Locks or unlocks navigation controls.
     *
     * When navigation is locked, certain operations (for example, orbit, pan, or fit-to-view)
     * are disabled.
     *
     * @param {boolean} value True if the navigation should be locked.
     *
     * @see {@link Autodesk.Viewing.Viewer3D#setNavigationLockSettings}
     * 
     * @alias Autodesk.Viewing.Viewer3D#setNavigationLock
     */
    Viewer3D.prototype.setNavigationLock = function(value)
    {
        if (this.navigation.getIsLocked() !== value) {
            this.navigation.setIsLocked(value);
            this.dispatchEvent({ type: et.NAVIGATION_MODE_CHANGED_EVENT, id: this.getActiveNavigationTool() });
        }
    };

    /**
     * Gets the current state of the navigation lock.
     * @returns {boolean} True if the navigation controls are currently locked.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getNavigationLock
     */
    Viewer3D.prototype.getNavigationLock = function()
    {
        return this.navigation.getIsLocked();
    };

    /**
     * Updates the configuration of the navigation lock,
     * i.e., which actions are available when navigation is locked.
     *
     * The configurable actions are 'orbit', 'pan', 'zoom', 'roll', 'fov', 'walk', or 'gotoview'.
     * By default, none of the actions are enabled when the navigation is locked.
     *
     * @param {object} settings Map of <action>:<boolean> pairs specifying
     * whether the given action is *enabled* even when the navigation is locked.
     *
     * @see {@link Autodesk.Viewing.Viewer3D#setNavigationLock}
     * 
     * @alias Autodesk.Viewing.Viewer3D#setNavigationLockSettings
     */
    Viewer3D.prototype.setNavigationLockSettings = function(settings)
    {
        this.navigation.setLockSettings(settings);
        this.dispatchEvent({ type: et.NAVIGATION_MODE_CHANGED_EVENT, id: this.getActiveNavigationTool() });
    };

    /**
     * Gets the current configuration of the navigation lock.
     *  @returns {object} Map of <action>:<boolean> pairs specifying
     * whether the given action is *enabled* even when the navigation is locked.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getNavigationLockSettings
     */
    Viewer3D.prototype.getNavigationLockSettings = function()
    {
        return this.navigation.getLockSettings();
    };

    /**
     * Swaps the current navigation tool for the tool with the provided name.
     * Will trigger NAVIGATION_MODE_CHANGED event if the mode actually changes.
     *
     * @param {string} [toolName] - The name of the tool to activate. By default it will switch to the default tool.
     *
     * @returns {boolean} - True if the tool was set successfully. False otherwise.
     *
     * @see {@link Viewer3D#getActiveNavigationTool|getActiveNavigationTool()}
     * 
     * @alias Autodesk.Viewing.Viewer3D#setActiveNavigationTool
     */
    Viewer3D.prototype.setActiveNavigationTool = function(toolName)
    {

        if(toolName === this._pushedTool || (!toolName && !this._pushedTool))
            return true;


        if( this._pushedTool ) {

            if( !this.impl.controls.deactivateTool(this._pushedTool) ) {
                return false;
            }

            // Need to reset the activeName of the default tool, since "orbit",
            // "freeorbit", "dolly" and "pan" share the same instance.
            this.impl.controls.setToolActiveName(this.getDefaultNavigationToolName());
            this._pushedTool = null;
        }

        var isDefault = !toolName || toolName === this.getDefaultNavigationToolName();

        if (isDefault && this._pushedTool === null) {
            this.dispatchEvent({ type: et.NAVIGATION_MODE_CHANGED_EVENT, id: this.getDefaultNavigationToolName() });
            return true;
        }

        if( this.impl.controls.activateTool(toolName) ) {
            this._pushedTool = toolName;
            this.dispatchEvent({ type: et.NAVIGATION_MODE_CHANGED_EVENT, id: this._pushedTool });
            return true;
        }

        return false;
    };

    /**
     * Returns the name of the active navigation tool.
     * @returns {string} - The tool's name.
     *
     * @see {@link Viewer3D#setActiveNavigationTool|setActiveNavigationTool()}
     * 
     * @alias Autodesk.Viewing.Viewer3D#getActiveNavigationTool
     */
    Viewer3D.prototype.getActiveNavigationTool = function()
    {
        return this._pushedTool ? this._pushedTool : this._defaultNavigationTool;
    };

    /**
     * Sets the default navigation tool. This tool will always sit beneath the navigation tool on the tool stack.
     *
     * @param {string} toolName - The name of the new default navigation tool.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setDefaultNavigationTool
     */
    Viewer3D.prototype.setDefaultNavigationTool = function(toolName)
    {
        if (this._defaultNavigationTool) {
            this.impl.controls.deactivateTool(this._defaultNavigationTool);
        }

        if (this._pushedTool) {
            this.impl.controls.deactivateTool(this._pushedTool);
        }

        this.impl.controls.activateTool(toolName);
        this._defaultNavigationTool = toolName;

        if (this._pushedTool) {
            this.impl.controls.activateTool(this._pushedTool);
        }
    };

    /**
     * Returns the default navigation tool
     *
     * @returns {Object} - The default navigation tool.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getDefaultNavigationToolName
     */
    Viewer3D.prototype.getDefaultNavigationToolName = function()
    {
        return this._defaultNavigationTool;
    };

    /**
     * Gets the current camera vertical field of view.
     * @returns { number } - the field of view in degrees.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getFOV
     */
    Viewer3D.prototype.getFOV = function()
    {
        return this.navigation.getVerticalFov();
    };

    /**
     * Sets the current cameras vertical field of view.
     * @param { number } degrees - Field of view in degrees.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setFOV
     */
    Viewer3D.prototype.setFOV = function(degrees)
    {
        this.navigation.setVerticalFov(degrees, true);
    };

    /**
     * Gets the current camera focal length.
     * @returns { number } - the focal length in millimetres.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getFocalLength
     */
    Viewer3D.prototype.getFocalLength = function()
    {
        return this.navigation.getFocalLength();
    };

    /**
     * Sets the current cameras focal length.
     * @param { number } mm - Focal length in millimetres
     * 
     * @alias Autodesk.Viewing.Viewer3D#setFocalLength
     */
    Viewer3D.prototype.setFocalLength = function(mm)
    {
        this.navigation.setFocalLength(mm, true);
    };

    /**
     * Hides all lines in the scene.
     * @param {boolean} hide
     * 
     * @alias Autodesk.Viewing.Viewer3D#hideLines
     */
    Viewer3D.prototype.hideLines = function(hide){
        this.prefs.set('lineRendering', !hide);
        var that = this;

        this.impl.hideLines(hide);
    };

    /**
     * Hides all points in the scene.
     * @param {boolean} hide
     * 
     * @alias Autodesk.Viewing.Viewer3D#hidePoints
     */
    Viewer3D.prototype.hidePoints = function(hide){
        this.prefs.set('pointRendering', !hide);
        var that = this;

        this.impl.hidePoints(hide);
    };

    /**
     * Turns edge topology display on/off (where available)
     * @param {boolean} show - true to turn edge topology display on, false to turn edge topology display off
     * 
     * @alias Autodesk.Viewing.Viewer3D#setDisplayEdges
     */
    Viewer3D.prototype.setDisplayEdges = function(show) {
        this.prefs.set('edgeRendering', show);
        this.impl.setDisplayEdges(show);
    };

    /**
     * @deprecated
     * Applies the camera to the current viewer's camera.
     * @param {THREE.Camera} camera - the camera to apply.
     * @param {boolean} [fit=false] - Do a fit to view after transition.
     * 
     * @alias Autodesk.Viewing.Viewer3D#applyCamera
     */
    Viewer3D.prototype.applyCamera = function(camera, fit) {
        this.impl.setViewFromCamera(camera, true);
        if (fit)
            this.fitToView();
    };

    /**
     * Fits camera to objects by ID. It fits the entire model if no ID is provided.
     * Operation will fit to the model's bounding box when its object tree is not available.
     *
     * @param {number[]|null} [objectIds=null] array of Ids to fit into the view. Avoid passing this value to fit the entire model.
     * @param {Autodesk.Viewing.Model} [model] - The model containing the ``objectIds``.
     * @param {boolean} [immediate=false] - true to avoid the default transition.
     * 
     * @fires Autodesk.Viewing#FIT_TO_VIEW_EVENT
     * @fires Autodesk.Viewing#AGGREGATE_FIT_TO_VIEW_EVENT
     * 
     * @alias Autodesk.Viewing.Viewer3D#fitToView
     */
    Viewer3D.prototype.fitToView = function(objectIds, model, immediate){

        var selection = [];
        if (objectIds) {
            if (objectIds.length > 0 && objectIds[0].model) {
                // Aggregated selection being passed in.
                selection = objectIds;
            } else if (objectIds.length > 0) {
                // Backwards compatibility interface for single model.
                selection.push({
                    model: (model || this.model),
                    selection: objectIds
                });
            }
        }

        this.impl.fitToView(selection, immediate);
        // Event gets fired from `impl`
    };

    /**
     * Modifies a click action configuration entry.
     * @param {string} what - which click config to modify (one of "click", "clickAlt", "clickCtrl", "clickShift", "clickCtrlShift").
     * @param {string} where - hit location selector (one of "onObject", "offObject").
     * @param {string[]} newAction - action list (containing any of "setCOI", "selectOnly", "selectToggle", "deselectAll", "deselectAll", "isolate", "showAll", "hide", "focus").
     * @returns {boolean} False if specified entry is not found, otherwise true.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setClickConfig
     */
    Viewer3D.prototype.setClickConfig = function(what, where, newAction)
    {
        var config = this.clickHandler ? this.clickHandler.getClickBehavior()
            : this.impl.controls.getClickBehavior();

        if( what in config )
        {
            var actions = config[what];
            if( where in actions )
            {
                actions[where] = newAction;
                return true;
            }
        }
        return false;
    };

    /**
     * Fetch a click action configuration entry.
     * @param {string} what - which click config to fetch (one of "click", "clickAlt", "clickCtrl", "clickShift", "clickCtrlShift").
     * @param {string} where - hit location selector (one of "onObject", "offObject").
     * @returns {array} action list for the given entry or null if not found.
     * 
     * @alias Autodesk.Viewing.Viewer3D#getClickConfig
     */
    Viewer3D.prototype.getClickConfig = function(what, where)
    {
        var config = this.clickHandler ? this.clickHandler.getClickBehavior()
            : this.impl.controls.getClickBehavior();

        if( what in config )
        {
            var actions = config[what];
            if( where in actions )
                return actions[where];
        }
        return null;
    };

    /**
     * Modify the default click behaviour for the viewer.
     * @param {boolean} state - If true the default is to set the center of interest. If false the default is single select.
     * @param {boolean} [updatePrefs=true] - If true, the user preferences will be updated.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setClickToSetCOI
     */
    Viewer3D.prototype.setClickToSetCOI = function(state, updatePrefs)
    {
        if (updatePrefs !== false)
            this.prefs.set('clickToSetCOI', state);

        var currentOn = this.getClickConfig("click", "onObject");
        if( state )
        {
            if( currentOn.indexOf("setCOI") === -1 ) // Not already set?
            {
                this.setClickConfig("click", "onObject",  [ "setCOI" ]);
            }
        }
        else if( currentOn.indexOf("setCOI") >= 0 ) // Is currently set?
        {
            this.setClickConfig("click", "onObject",  [ "selectOnly" ]);
        }
    };


    /**
     * Initializes all gui settings to their defaults or to the session stored setting
     * This gives session stored settings priority.
     * Invoked internally during initialization.
     * 
     * @private
     */
    Viewer3D.prototype.initSettings = function() {

        this.prefs.load(DefaultSettings);

        this.prefs.tag('3d');
        this.prefs.tag('2d');
        this.prefs.untag('2d', [ // 3d only
            'viewCube',
            'alwaysUsePivot',
            'zoomTowardsPivot',
            'reverseHorizontalLookDirection',
            'reverseVerticalLookDirection',
            'orbitPastWorldPoles',
            'clickToSetCOI',
            'ghosting',
            'optimizeNavigation',
            'ambientShadows',
            'antialiasing',
            'groundShadow',
            'groundReflection',
            'lineRendering',
            'edgeRendering',
            'lightPreset',
            'envMapBackground',
            'firstPersonToolPopup',
            'bimWalkToolPopup'
        ]);
        this.prefs.untag('3d', [ // 2d only
            'grayscale',
            'swapBlackAndWhite'
        ]);

        // Apply settings
        this.setQualityLevel(this.prefs.ambientShadows, this.prefs.antialiasing);
        this.setGroundShadow(this.prefs.groundShadow);
        this.setGroundReflection(this.prefs.groundReflection);
        this.setGhosting(this.prefs.ghosting);
        this.setProgressiveRendering(this.prefs.progressiveRendering);
        this.setGrayscale(this.prefs.grayscale);
        this.setSwapBlackAndWhite(this.prefs.swapBlackAndWhite);
        this.setClickToSetCOI(this.prefs.clickToSetCOI);
        this.setOptimizeNavigation(this.prefs.optimizeNavigation);
        this.hideLines(!this.prefs.lineRendering);
        this.hidePoints(!this.prefs.pointRendering);
        this.setDisplayEdges(this.prefs.edgeRendering);
        this.setEnvMapBackground(this.prefs.envMapBackground);
        this.setFirstPersonToolPopup(this.prefs.firstPersonToolPopup);
        this.setBimWalkToolPopup(this.prefs.bimWalkToolPopup);

        this.navigation.setUsePivotAlways(this.prefs.alwaysUsePivot);
        this.navigation.setReverseZoomDirection(this.prefs.reverseMouseZoomDir);
        this.navigation.setReverseHorizontalLookDirection(this.prefs.reverseHorizontalLookDirection);
        this.navigation.setReverseVerticalLookDirection(this.prefs.reverseVerticalLookDirection);
        this.navigation.setZoomTowardsPivot(this.prefs.zoomTowardsPivot);
        this.navigation.setOrbitPastWorldPoles(this.prefs.orbitPastWorldPoles);
        this.navigation.setUseLeftHandedInput(this.prefs.leftHandedMouseSetup);
        this.navigation.setWheelSetsPivot(this.prefs.wheelSetsPivot);

        var bacStr = this.prefs.backgroundColorPreset;
        if (bacStr) {
            try {
                var bac = JSON.parse(bacStr);
                this.impl.setClearColors(bac[0],bac[1],bac[2],bac[3],bac[4],bac[5]);
            } catch(e) {
                this.prefs.set("backgroundColorPreset", null);
            }
        }

        // done last, so that if the environment has a background color, it
        // overrides any previous background color preference.
        var lightPreset = /*viewer.model.is2d() ? DefaultLightPreset2d :*/ this.prefs.lightPreset;
        this.impl.setLightPreset(lightPreset);
        // Need to save the loaded light preset for when the initial model loaded is 2D.
        this.impl.saveLightPreset();
    };

    /**
     * Sets the Light Presets (Environments) for the Viewer.
     *
     * Not applicable to 2D.
     *
     * Sets the preference in the UI
     * @param {Number} index - where
     * - 0 Simple Grey
     * - 1 Sharp Highlights
     * - 2 Dark Sky
     * - 3 Grey Room
     * - 4 Photo Booth
     * - 5 Tranquility
     * - 6 Infinity Pool
     * - 7 Simple White
     * - 8 Riverbank
     * - 9 Contrast
     * - 10 Rim Highlights
     * - 11 Cool Light
     * - 12 Warm Light
     * - 13 Soft Light
     * - 14 Grid Light
     * - 15 Plaza
     * - 16 Snow Field
     * @note this list is copied from the ones in Environments.js
     * 
     * @alias Autodesk.Viewing.Viewer3D#setLightPreset
     */
    Viewer3D.prototype.setLightPreset = function (index) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setLightPreset is not applicable to 2D");
            return;
        }

        this.prefs.set('lightPreset', index);

        this.impl.setLightPreset(index);
    };

    /**
     *  Set or unset a view navigation option which requests that orbit controls always orbit around the currently set pivot point.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true to request use of the pivot point. When false some controls may pivot around the center of the view. (Currently applies only to the view-cube orbit controls.)
     * 
     * @alias Autodesk.Viewing.Viewer3D#setUsePivotAlways
     */
    Viewer3D.prototype.setUsePivotAlways = function (value) {
        this.prefs.set('alwaysUsePivot', value);
        this.navigation.setUsePivotAlways(value);
    };

    /**
     * Set or unset a view navigation option to reverse the default direction for camera dolly (zoom) operations.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true for reverse, false for default
     * 
     * @alias Autodesk.Viewing.Viewer3D#setReverseZoomDirection
     */
    Viewer3D.prototype.setReverseZoomDirection = function (value) {
        this.prefs.set('reverseMouseZoomDir', value);
        this.navigation.setReverseZoomDirection(value);
    };

    /**
     * Set or unset a view navigation option to reverse the default direction for horizontal look operations.
     *
     * Not applicable to 2D.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true for reverse, false for default
     * 
     * @alias Autodesk.Viewing.Viewer3D#setReverseHorizontalLookDirection
     */
    Viewer3D.prototype.setReverseHorizontalLookDirection = function (value) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setReverseHorizontalLookDirection is not applicable to 2D");
            return;
        }

        this.prefs.set('reverseHorizontalLookDirection', value);
        this.navigation.setReverseHorizontalLookDirection(value);
    };

    /**
     * Set or unset a view navigation option to reverse the default direction for vertical look operations.
     *
     * Not applicable to 2D.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true for reverse, false for default
     * 
     * @alias Autodesk.Viewing.Viewer3D#setReverseVerticalLookDirection
     */
    Viewer3D.prototype.setReverseVerticalLookDirection = function (value) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setReverseVerticalLookDirection is not applicable to 2D");
            return;
        }

        this.prefs.set('reverseVerticalLookDirection', value);
        this.navigation.setReverseVerticalLookDirection(value);
    };

    /**
     * Get the state of the view navigation option that requests the default direction for camera dolly (zoom) operations to be towards the camera pivot point.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true for towards the pivot, false for default
     * 
     * @alias Autodesk.Viewing.Viewer3D#setZoomTowardsPivot
     */
    Viewer3D.prototype.setZoomTowardsPivot = function (value) {
        this.prefs.set('zoomTowardsPivot', value);
        this.navigation.setZoomTowardsPivot(value);
    };

    /**
     * Set or unset a view navigation option to allow the orbit controls to move the camera beyond the north and south poles (world up/down direction). In other words, when set the orbit control will allow the camera to rotate into an upside down orientation. When unset orbit navigation should stop when the camera view direction reaches the up/down direction.
     *
     * Not applicable to 2D.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true to allow orbiting past the poles.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setOrbitPastWorldPoles
     */
    Viewer3D.prototype.setOrbitPastWorldPoles = function (value) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setOrbitPastWorldPoles is not applicable to 2D");
            return;
        }

        this.prefs.set('orbitPastWorldPoles', value);
        this.navigation.setOrbitPastWorldPoles(value);
    };

    /**
     * Set or unset a view navigation option which requests that mouse buttons be reversed from their default assignment. i.e. Left mouse operation becomes right mouse and vice versa.
     *
     *  Sets the preference in the UI
     *  @param {boolean} value - value of the option, true to request reversal of mouse button assignments.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setUseLeftHandedInput
     */
    Viewer3D.prototype.setUseLeftHandedInput = function (value) {
        this.prefs.set('leftHandedMouseSetup', value);
        this.navigation.setUseLeftHandedInput(value);
    };

    /**
     * Set visibility for a single layer, or for all layers.
     *
     * @param {?Array} nodes - An array of layer nodes, or a single layer node, or null for all layers
     * @param {boolean} visible - true to show the layer, false to hide it
     * @param {boolean=} [isolate] - true to isolate the layer
     * 
     * @alias Autodesk.Viewing.Viewer3D#setLayerVisible
     */
    Viewer3D.prototype.setLayerVisible = function (nodes, visible, isolate) {

        var layers = this.impl.layers;
        if (nodes === null) {
            if (visible) {
                layers.showAllLayers();
            } else {
                layers.hideAllLayers();
            }
        } else {

            if(!Array.isArray(nodes)) {
                nodes = [nodes];
            }

            if (isolate) {
                layers.hideAllLayers();
            }

            this.impl.setLayerVisible(nodes, visible);
        }
        this.dispatchEvent({type: et.LAYER_VISIBILITY_CHANGED_EVENT});
    };

    /**
     * Returns true if the layer is visible.
     *
     * @param {Object} node - Layer node
     * @returns {boolean} true if the layer is visible
     * 
     * @alias Autodesk.Viewing.Viewer3D#isLayerVisible
     */
    Viewer3D.prototype.isLayerVisible = function (node) {
        return !!(node && node.isLayer && this.impl.isLayerVisible(node));
    };

    /**
     * Returns true if any layer is hidden.
     *
     * @returns {boolean} true if any layer is hidden
     * 
     * @alias Autodesk.Viewing.Viewer3D#anyLayerHidden
     */
    Viewer3D.prototype.anyLayerHidden = function () {

        var root = this.impl.getLayersRoot();
        var layers = root && root.children;
        var layersCount = (layers ? layers.length : 0);

        for (var i = 0; i < layersCount; ++i) {
            if(!this.impl.layers.isLayerVisible(layers[i])) {
                return true;
            }
        }

        return false;
    };

    /**
     * Returns true if all layers are hiden.
     *
     * @returns {boolean} true if all layers are hidden
     * 
     * @alias Autodesk.Viewing.Viewer3D#allLayersHidden
     */
    Viewer3D.prototype.allLayersHidden = function () {

        var root = this.impl.getLayersRoot();
        var layers = root && root.children;
        var layersCount = (layers ? layers.length : 0);

        for (var i = 0; i < layersCount; ++i) {
            if (this.impl.layers.isLayerVisible(layers[i])) {
                return false;
            }
        }

        return true;
    };

    /**
     * If enabled, set ground shadow color
     *
     * Not applicable to 2D
     *
     * @param {THREE.Color} color
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundShadowColor
     */
    Viewer3D.prototype.setGroundShadowColor = function(color) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundShadowColor is not applicable to 2D");
            return;
        }

        this.impl.setGroundShadowColor(color);
    };

    /**
     * If enabled, set ground shadow alpha
     *
     * Not applicable to 2D
     *
     * @param {float} alpha
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundShadowAlpha
     */
    Viewer3D.prototype.setGroundShadowAlpha = function(alpha) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundShadowAlpha is not applicable to 2D");
            return;
        }

        this.impl.setGroundShadowAlpha(alpha);
    };

    /**
     * If enabled, set ground reflection color. This is reset to default when reflections toggled off.
     *
     * Not applicable to 2D
     *
     * @param {THREE.Color} color
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundReflectionColor
     */
    Viewer3D.prototype.setGroundReflectionColor = function(color) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundReflectionColor is not applicable to 2D");
            return;
        }

        this.impl.setGroundReflectionColor(color);
    };

    /**
     * If enabled, set ground reflection alpha. This is reset to default when reflections toggled off.
     *
     * Not applicable to 2D
     *
     * @param {float} alpha
     * 
     * @alias Autodesk.Viewing.Viewer3D#setGroundReflectionAlpha
     */
    Viewer3D.prototype.setGroundReflectionAlpha = function(alpha) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.setGroundReflectionAlpha is not applicable to 2D");
            return;
        }

        this.impl.setGroundReflectionAlpha(alpha);
    };

    /**
     * Returns a list of active cut planes
     *
     * Not applicable to 2D
     *
     * @return {THREE.Vector4[]} List of Vector4 plane representation {x:a, y:b, z:c, w:d}
     * 
     * @alias Autodesk.Viewing.Viewer3D#getCutPlanes
     */
    Viewer3D.prototype.getCutPlanes = function() {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getCutPlanes is not applicable to 2D");
            return [];
        }

        return this.impl.getCutPlanes();
    };

    /**
     * Apply a list of cut planes
     *
     * Not applicable to 2D
     *
     * @param {THREE.Vector4[]} planes - List of Vector4 plane representation: {x:a, y:b, z:c, w:d}
     * Plane general equation: ax + by + cz + d = 0 where a, b, and c are not all zero
     * Passing an empty list or null is equivalent to setting zero cut planes
     * 
     * @alias Autodesk.Viewing.Viewer3D#setCutPlanes
     */
    Viewer3D.prototype.setCutPlanes = function(planes) {
        if( this.model && this.model.is2d() )
        {
            logger.warn("Viewer3D.getCutPlanes is not applicable to 2D");
            return;
        }

        this.impl.setCutPlanes(planes);
    };

    /**
     * Captures the current screen image as Blob URL
     * Blob URL can be used like a regular image url (e.g., window.open, img.src, etc)
     * If width and height are 0, returns asynchronously and calls the callback with an image as Blob URL with dimensions equal to current canvas dimensions
     * If width and height are given, returns asynchronously and calls the callback with the resized image as Blob URL
     * If no callback is given, displays the image in a new window.
     * Optional overlayRenderer can be supplied, in order to render an overlay on top of the renderer image.
     * @param  {number} [w]  width of the requested image
     * @param  {number} [h]  height of the requested image
     * @param  {Function} [cb] callback
     * @param  {Function} [overlayRenderer] overlayRenderer
     * @return {DOMString} screenshot image Blob URL, if no parameters are given
     * 
     * @alias Autodesk.Viewing.Viewer3D#getScreenShot
     */
    Viewer3D.prototype.getScreenShot = function(w, h, cb, overlayRenderer) {
        return ScreenShot.getScreenShotLegacy(this, w, h, cb, overlayRenderer);
    };

    /**
     * Sets the object context menu.
     * @param {?ObjectContextMenu=} [contextMenu]
     */
    Viewer3D.prototype.setContextMenu = function (contextMenu) {

        if (this.contextMenu) {

            // Hide the current context menu, just in case it's open right now.
            // This does nothing if the context menu is not open.
            //
            this.contextMenu.hide();
        }

        this.contextMenu = contextMenu || null; // to avoid undefined
    };

    /**
     * Activates the default context menu.<br>
     * Contains options Isolate, Hide selected, Show all objects, Focus and Clear selection.
     *
     * @returns {boolean} Whether the default context menu was successfully set (true) or not (false)
     */
    Viewer3D.prototype.setDefaultContextMenu = function() {

        var ave = Autodesk.Viewing.Extensions;
        if (ave && ave.ViewerObjectContextMenu) {
            this.setContextMenu(new ave.ViewerObjectContextMenu(this));
            return true;
        }
        return false;
    };

    Viewer3D.prototype.triggerContextMenu = function (event) {
        if (this.config && this.config.onTriggerContextMenuCallback) {
            this.config.onTriggerContextMenuCallback(event);
        }

        if (this.contextMenu) {
            this.contextMenu.show(event);
            return true;
        }
        return false;
    };

    Viewer3D.prototype.triggerSelectionChanged = function (dbId) {
        if (this.config && this.config.onTriggerSelectionChangedCallback) {
            this.config.onTriggerSelectionChangedCallback(dbId);
        }
    };

    Viewer3D.prototype.triggerDoubleTapCallback = function (event) {
        if (this.config && this.config.onTriggerDoubleTapCallback) {
            this.config.onTriggerDoubleTapCallback(event);
        }
    };

    Viewer3D.prototype.triggerSingleTapCallback = function (event) {
        if (this.config && this.config.onTriggerSingleTapCallback) {
            this.config.onTriggerSingleTapCallback(event);
        }
    };

    Viewer3D.prototype.triggerSwipeCallback = function (event) {
        if (this.config && this.config.onTriggerSwipeCallback) {
            this.config.onTriggerSwipeCallback(event);
        }
    };

    Viewer3D.prototype.initContextMenu = function() {

        // Disable the browser's default context menu by default, or if explicitly specified.
        //
        var disableBrowserContextMenu = !this.config || (this.config.hasOwnProperty("disableBrowserContextMenu") ? this.config.disableBrowserContextMenu : true);
        if (disableBrowserContextMenu) {
            this.onDefaultContextMenu = function (e) {
                e.preventDefault();
            };
            this.container.addEventListener('contextmenu', this.onDefaultContextMenu, false);
        }

        var self = this;

        var canvas = this.canvas || this.container;

        this.onMouseDown = function(event) {
            if (isRightClick(event, self.navigation)) {
                self.startX = event.clientX;
                self.startY = event.clientY;
            }
        }

        canvas.addEventListener( 'mousedown', this.onMouseDown);

        this.onMouseUp = function(event) {
            if (isRightClick(event, self.navigation) && event.clientX === self.startX && event.clientY === self.startY) {
                self.triggerContextMenu(event);
            }
            return true;
        }

        canvas.addEventListener( 'mouseup', this.onMouseUp, false);
    };


    /**
     * Registers a new callback that modifies the context menu.
     * This allows extensions and others to add, remove, or change items in the context menu.
     * Extensions that call registerContextMenuCallback() should call unregisterContextMenuCallback() in their unload().
     * @param {string} id - Unique id to identify this callback. Used by unregisterContextMenuCallback().
     * @param {function(Array, Object)} callback - Will be called before the context menu is displayed.
     * @see Viewer.unregisterContextMenuCallback
     * @see ObjectContextMenu.buildMenu
     *
     * @example
     * // Here's an example that appends a new context menu item:
     *
     * viewer.registerContextMenuCallback('MyExtensionName', function (menu, status) {
 *     if (status.hasSelected) {
 *         menu.push({
 *             title: 'My new context menu item with selected objects',
 *             target: function () {
 *                 alert('Do something with selected objects');
 *         }});
 *     } else {
 *         menu.push({
 *             title: 'My new context menu item, no selected objects',
 *             target: function () {
 *                 alert('Do something else');
 *         }});
 *     }
 * });
 */
    Viewer3D.prototype.registerContextMenuCallback = function (id, callback) {
        this.contextMenuCallbacks[id] = callback;
    };

    /**
     * Unregisters an existing callback that modifies the context menu.
     * Extensions that call registerContextMenuCallback() should call unregisterContextMenuCallback() in their unload().
     * @param {string} id - Unique id to identify this callback.
     * @returns {boolean} true if the callback was unregistered successfully.
     * @see Viewer.registerContextMenuCallback
     */
    Viewer3D.prototype.unregisterContextMenuCallback = function (id) {
        if (id in this.contextMenuCallbacks) {
            delete this.contextMenuCallbacks[id];
            return true;
        }
        return false;
    };

    /**
     * Runs all registered context menu callbacks.
     * @param {array} menu - Context menu items.
     * @param {Object} status - Information about nodes.
     * @see ObjectContextMenu.buildMenu
     * @private
     */
    Viewer3D.prototype.runContextMenuCallbacks = function (menu, status) {
        for (var id in this.contextMenuCallbacks) {
            if (this.contextMenuCallbacks.hasOwnProperty(id)) {
                this.contextMenuCallbacks[id](menu, status);
            }
        }
    };

    /**
     * Join a live review session.
     *
     * @param {string} [sessionId] - The live review session id to join.
     *
     * @private
     */
    Viewer3D.prototype.joinLiveReview = function (sessionId) {
        if (!this.liveReviewClient) {
            this.liveReviewClient = new LiveReviewClient(this);
        }

        var liveReviewClient = this.liveReviewClient;
        loadDependency("lmv_io", "socket.io-1.3.5.js", function(){
            liveReviewClient.joinLiveReviewSession(sessionId);
        });
    };

    /**
     * Leave a live review session.
     *
     * @private
     */
    Viewer3D.prototype.leaveLiveReview = function () {
        if (this.liveReviewClient) {
            this.liveReviewClient.leaveLiveReviewSession();
        }
    };

    /**
     * Set model units
     * @param Model units
     */
    Viewer3D.prototype.setModelUnits = function(modelUnits) {
        if (this.model) {
            this.model.getData().overriddenUnits = modelUnits;
        }
    };

    /**
     * Calculates the pixel position in client space coordinates of a point in world space.<br>
     * See also
     * {@link Autodesk.Viewing.Viewer3D#clientToWorld|clientToWorld()}.
     * @param {THREE.Vector3} point Point in world space coordinates.
     * @param {THREE.Camera} camera Optional camera to use - default is the viewer's native camera.
     * @returns {THREE.Vector3} Point transformed and projected into client space coordinates. Z value is 0.
     * 
     * @alias Autodesk.Viewing.Viewer3D#worldToClient
     */
    Viewer3D.prototype.worldToClient = function(point, camera = this.impl.camera) {
        return this.impl.worldToClient(point, camera);
    };

    /**
     * Given coordinates in pixel screen space it returns information of the underlying geometry node.
     * Hidden nodes will not be taken into account. Returns null if there is no geometry in the specified location.
     * For 2d models, it will return null outside the paper.<br>
     * See also
     * {@link Autodesk.Viewing.Viewer3D#worldToClient|worldToClient()}.
     *
     * @param {Number} clientX - X coordinate where 0 is left
     * @param {Number} clientY - Y coordinate where 0 is top
     * @param {Boolean} [ignoreTransparent] - Ignores transparent materials
     * @returns {Object|null} contains point attribute. 3d models have additional attributes.
     * 
     * @alias Autodesk.Viewing.Viewer3D#clientToWorld
     */
    Viewer3D.prototype.clientToWorld = function(clientX, clientY, ignoreTransparent) {

        return this.impl.clientToWorld(clientX, clientY, ignoreTransparent);
    };

    /**
     * Expose if the model has topology information downloaded.
     * Only applicable to 3D models.
     * @returns {boolean} value - Indicates whether the model has topology information.
     * 
     * @alias Autodesk.Viewing.Viewer3D#modelHasTopology
     */
    Viewer3D.prototype.modelHasTopology = function() {

        if (this.model && this.model.hasTopology()) {
            return true;
        }

        return false;
    };

    /**
     * Changes the color of the selection for a particular selection type.
     * - Autodesk.Viewing.SelectionMode.MIXED
     *   - Sets the same color for regular and overlayed selection.
     * - Autodesk.Viewing.SelectionMode.REGULAR
     *   - Sets the color of regular selection.
     * - Autodesk.Viewing.SelectionMode.OVERLAYED
     *   - Sets the color of overlayed selection.
     *
     * @example
     *  viewer.setSelectionColor(new THREE.Color(0xFF0000), Autodesk.Viewing.SelectionMode.MIXED); // red color
     * @param {THREE.Color} color
     * @param {number} selectionType a member of Autodesk.Viewing.SelectionMode.
     * @alias Autodesk.Viewing.Viewer3D#setSelectionColor
     */
    Viewer3D.prototype.setSelectionColor = function(color, selectionType) {
        this.impl.setSelectionColor(color, selectionType);
    };

    /**
     * Changes the color of the selection for 2D drawings.
     *
     * @example
     *  viewer.set2dSelectionColor(new THREE.Color(0xFF0000), 0.1); // red color, opacity of 0.1
     * @param {THREE.Color} color
     * @param {number} opacity
     * 
     * @alias Autodesk.Viewing.Viewer3D#set2dSelectionColor
     */
    Viewer3D.prototype.set2dSelectionColor = function(color, opacity) {
        this.impl.set2dSelectionColor(color, opacity);
    };

    /**
     * Sets the current UI theme of the viewer.
     * Supported values are "light-theme" and "dark-theme", which is the default.
     * 
     * @param {string} name - Name of the theme, it will be added to the viewer's container class list.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setTheme
     */
    Viewer3D.prototype.setTheme = function(name) {

        var classList = this.container.classList;

        // Remove previous themes.
        for (var i = 0; i < classList.length; ++i) {
            var index = classList[i].indexOf('-theme');
            if (index !== -1) {
                classList.remove(classList[i--]);
            }
        }

        // Set current theme.
        classList.add(name);
    }

    /**
     * Highlight an object with a theming color that is blended with the original object's material.
     * @param {number} dbId
     * @param {THREE.Vector4} color - (r, g, b, intensity), all in [0,1].
     * @param {Autodesk.Viewing.Model} [model] - For multi-model support.
     * @param {boolean} [recursive] - Should apply theming color recursively to all child nodes.
     * 
     * @alias Autodesk.Viewing.Viewer3D#setThemingColor
     */
    Viewer3D.prototype.setThemingColor = function(dbId, color, model, recursive) {
        // use default RenderModel by default
        model = model || this.model;

        model.setThemingColor(dbId, color, recursive);

        // we changed the scene to apply theming => trigger re-render
        this.impl.invalidate(true);
    };

    /**
     * Restore original colors for all themed shapes.
     * @param {Autodesk.Viewing.Model} [model] - For multi-model support.
     * 
     * @alias Autodesk.Viewing.Viewer3D#clearThemingColors
     */
    Viewer3D.prototype.clearThemingColors = function(model) {
        // use default RenderModel by default
        model = model || this.model;

        model.clearThemingColors();

        // we changed the scene to apply theming => trigger re-render
        this.impl.invalidate(true);
    };

    /**
     * @deprecated
     * Transfer model from this viewer to another one - including state of selection, ghosting, and theming.
     * Note that this does not work with OTG models, because a single OTG geometry may be shared by different OTG models.
     */
    Viewer3D.prototype.transferModel = function(modelId, viewer) {

        var model = this.impl.findModel(modelId);
        if (!model) {
            // unknown modeId
            return;
        }

        // collect all selected db ids for this model
        var selectedIds = [];
        this.getAggregateSelection(function(model, dbId) {
            if (model.id==modelId) {
                selectedIds.push(dbId);
            }
        });

        // collect isolated/hidden nodes
        var isolatedIds = this.impl.visibilityManager.getIsolatedNodes(model);
        var hiddenIds   = this.impl.visibilityManager.getHiddenNodes(model);

        // export all materials and textures to MaterialManager of the other viewer
        // Note: Getting the materials from MaterialManager directly is the safest way for consistent state between both viewers.
        // E.g., enumerating the materials of the RenderModel instead would not work for 2 reasons:
        //  a) If the model is still loading, some materials would get lost: SvfLoader adds all materials in onModelRootLoadDone() already.
        //     But, RenderModel only knows materials for meshes that have been already loaded.
        //  b) The material hashes are only known to MaterialManager
        var modelMaterials = this.impl.matman().exportModelMaterials(model);

        // The unloadModel() call below also stops/destroys the model loader. Normally, this is wanted. Just in this case,
        // we want to keep the loader alive, so that the other viewer can load the rest of the model. Therefore,
        // we temporarily decouple it from the model and attach it again after transferring the model.
        var loader = model.loader;
        model.loader = null;

        // detach model from this viewer and discard all GPU resources.
        this.impl.unloadModel(model);

        // re-attach loader to model
        model.loader = loader;

        // Set rendering prefs before adding the model (to prevent flashing of the environment)
        if (model.isAEC() && model.is3d()) {
            viewer.impl.setLightPresetForAec();
        }
        viewer.impl.setRenderingPrefsFor2D(model.is2d());

        // pass model to other viewer
        viewer.impl.addModel(model);

        // import materials to new viewer
        // Note that it is essential to do export/import of materials in separate steps:
        //  - Exporting materials must be done before removing the model. After removeModel(),
        //    MaterialManager would not contain the material of this model anymore.
        //  - Importing materials must be done after adding the model to make sure that everything is properly initialized.
        //    E.g., the layerTexture would not be initialized otherwise.
        viewer.impl.matman().importModelMaterials(modelMaterials, modelId);

        // if the other viewer had no model before, make sure that the loadSpinner disappears.
        viewer._loadingSpinner.hide();

        // link running loader to new viewer
        if (model.loader && model.loader.viewer3DImpl===this.impl) {
            model.loader.viewer3DImpl = viewer.impl;
        }

        // if model is still loading, the worker will call onLoadComplete later. If the model is still loaded,
        // we do it immediately.
        if (model.getData().loadDone) {
            viewer.impl.onLoadComplete(model);
        }

        // recover selection
        viewer.impl.selector.setSelection(selectedIds, model);

        // recover isolated/hidden nodes (Note that hiddenIds are only used if no node is isolated)
        if (isolatedIds.length!=0)      viewer.impl.visibilityManager.isolate(isolatedIds, model);
        else if (hiddenIds.length!=0)   viewer.impl.visibilityManager.hide(hiddenIds, model);
    };

	/**
     * Temporarily remove a model from the Viewer, but keep loaders, materials, and geometry alive.
     *
     * @param {number|Autodesk.Viewing.Model} model - model id or Model object
     * @returns {boolean} true indicates success, i.e., modelId referred to a visible model that is now hidden
     * 
     * @see Autodesk.Viewing.Viewer3D#showModel
     * 
     * @alias Autodesk.Viewing.Viewer3D#hideModel
     */
    Viewer3D.prototype.hideModel = function(model) {
        var scene = this.impl.modelQueue();
        // find visible model with this id
        model = typeof model === 'number' ? scene.findModel(model) : model;
        
        if (!model) {
            return false;
        }

        // remove model from viewer - but without discarding materials
        this.impl.removeModel(model);

        // make this model available for later showModel() calls
        scene.addHiddenModel(model);

        return true;
    };

	/**
     * Make a previously hidden model visible again.
     *
     * @param {number|Autodesk.Viewing.Model} model - model id or Model object
     * @param {bool} preserveTools - disable automatic activation of default tool
     * @returns {boolean} true indicates success, i.e., ``model`` referred to a hidden model that is now visible
     * 
     * @see Autodesk.Viewing.Viewer3D#hideModel
     * 
     * @alias Autodesk.Viewing.Viewer3D#showModel
     */
    Viewer3D.prototype.showModel = function(model, preserveTools) {
        var scene = this.impl.modelQueue();
        model = typeof model === 'number' ? scene.findHiddenModel(model) : model;

        if (!model) {
            // modelId does not refer to any hidden model
            return false;
        }

        // remove model from list of hidden ones
        scene.removeHiddenModel(model);

        // add it to the viewer.
        this.impl.addModel(model, preserveTools);

        return true;
    };

    /**
     * @returns {Autodesk.Viewing.Model[]}
     * 
     * @alias Autodesk.Viewing.Viewer3D#getVisibleModels
     */
     Viewer3D.prototype.getVisibleModels = function() {
         var scene = this.impl.modelQueue();
         return scene.getModels().slice();
     };

    /**
     * @returns {Autodesk.Viewing.Model[]}
     * 
     * @alias Autodesk.Viewing.Viewer3D#getHiddenModels
     */
    Viewer3D.prototype.getHiddenModels = function() {
        var scene = this.impl.modelQueue();
        return scene.getHiddenModels().slice();
    };

     /**
      * @private
      */
    Viewer3D.prototype.restoreDefaultSettings = function() {

        var model = this.model;
        var tag = model.is2d() ? '2d' : '3d';
        this.prefs.reset(tag);
        
        this.impl.setupLighting(model);
        if (model.isAEC() && model.is3d()) {
            this.impl.setLightPresetForAec();
        }
        this.impl.setRenderingPrefsFor2D(!model.is3d());

        this.fireEvent({ type: et.RESTORE_DEFAULT_SETTINGS_EVENT });
    };

    /**
     * disable mouse-over highlight.
     * @param {boolean} disable - Indicates whether highlighting should be on or off. True to disable highlights, false to enable them.
     */

     /**
      * Disables roll-over highlighting.
      * 
     * @alias Autodesk.Viewing.Viewer3D#disableHighlight
      */
    Viewer3D.prototype.disableHighlight = function (disable) {
        this.impl.disableHighlight(disable);
    };

    /**
     * disable the selection of a loaded model.
     * @param {boolean} disable - true to disable selection, false to enable selection.
     * 
     * @alias Autodesk.Viewing.Viewer3D#disableSelection
     */
    Viewer3D.prototype.disableSelection = function (disable) {
        if(disable) {
            this.clearSelection();
        }
        this.impl.disableSelection(disable);
    };

    /**
     * check if the mouse-over highlight is disabled or not
     * 
     * @alias Autodesk.Viewing.Viewer3D#isHighlightDisabled
     */
    Viewer3D.prototype.isHighlightDisabled = function () {
       return this.impl.selector.highlightDisabled;
    };

    /**
     * check if the mouse-over highlight is paused or not
     * 
     * @alias Autodesk.Viewing.Viewer3D#isHighlightPaused
     */
    Viewer3D.prototype.isHighlightPaused = function () {
        return this.impl.selector.highlightPaused;
    };

    /**
     * check if the mouse-over highlight is active or not
     * @alias Autodesk.Viewing.Viewer3D#isHighlightActive
     */
    Viewer3D.prototype.isHighlightActive = function () {
        return !(this.impl.selector.highlightDisabled || this.impl.selector.highlightPaused);
    };

    /**
     * check if the selection of the loaded model is disabled or not
     * @alias Autodesk.Viewing.Viewer3D#isSelectionDisabled
     */
    Viewer3D.prototype.isSelectionDisabled = function () {
        return this.impl.selector.selectionDisabled;
    };

    /**
     * Activates the extension based on the extensionID and mode given. By default it takes the first available mode in getmodes();
     * @param {string} extensionID - The extension id.
     * @param {string} [mode]
     * 
     * @alias Autodesk.Viewing.Viewer3D#activateExtension
     */
    Viewer3D.prototype.activateExtension = function (extensionID, mode) {
        if (this.loadedExtensions && extensionID in this.loadedExtensions) {
            var extension =  this.loadedExtensions[extensionID];
            return extension.activate(mode);
        } else {
            logger.warn("Extension is not loaded or doesn't exist");
            return false;
        }
    };

    /**
     * Dectivates the extension based on the extensionID specified.
     * @param {string} extensionID
     * @alias Autodesk.Viewing.Viewer3D#deactivateExtension
     */
    Viewer3D.prototype.deactivateExtension = function (extensionID) {
        if(this.loadedExtensions && extensionID in this.loadedExtensions) {
            var extension = this.loadedExtensions[extensionID];
            return extension.deactivate();
        } else {
            logger.warn("Extension is not loaded or doesn't exist");
            return false;
        }
    };

    /**
     * Check if the extension is active or not by passing the extensionID.
     * @param {string} extensionID
     * @param {string} mode
     * @alias Autodesk.Viewing.Viewer3D#isExtensionActive
     */
    Viewer3D.prototype.isExtensionActive = function (extensionID, mode) {
        if(this.loadedExtensions && extensionID in this.loadedExtensions) {
            var extension = this.loadedExtensions[extensionID];
            var activeStatus = extension.isActive(mode);
            return activeStatus;

        } else {
            logger.warn("Extension is not loaded or doesn't exist");
            return false;
        }
    };

    /**
     * Check if the extension is loaded or not by passing the extensionID.
     * @param {string} extensionID
     * @alias Autodesk.Viewing.Viewer3D#isExtensionLoaded
     */
    Viewer3D.prototype.isExtensionLoaded = function (extensionID) {
        return this.loadedExtensions && extensionID in this.loadedExtensions;
    };

    /**
     * Get a list of all the extensions that are currently loaded.
     * @returns {string[]}
     * @alias Autodesk.Viewing.Viewer3D#getLoadedExtensions
     */
    Viewer3D.prototype.getLoadedExtensions = function () {
        return this.loadedExtensions || [];
    };

    /**
     * Get a list of all the modes that are available for the given extensionID.
     * @param {string} extensionID
     * @alias Autodesk.Viewing.Viewer3D#getExtensionModes
     */
    Viewer3D.prototype.getExtensionModes = function (extensionID) {
        if (this.loadedExtensions && extensionID in this.loadedExtensions) {
            var extension = this.loadedExtensions[extensionID];
            return extension.getModes();
        } else {
            console.warn("Extension is not loaded or doesn't exist");
            return [];
        }
    };

    /**
     * Reorders elements in the viewer container that need a certain order in the DOM to work correctly,
     * according to what's set in containerLayerOrder
     * @param {object} element - the element that needs reordering
     */
    Viewer3D.prototype.reorderElements = function(element) {
        var id = element.getAttribute('layer-order-id');
        var order = this.containerLayerOrder.indexOf(id);

        var nextElement = null;

        if (order !== -1) {
            for (var i = order + 1; i < this.containerLayerOrder.length && !nextElement; i++) {
                nextElement = this.container.querySelector('[layer-order-id="' + this.containerLayerOrder[i] + '"]');
            }
        }

        (nextElement) ? this.container.insertBefore(element, nextElement) : this.container.appendChild(element);
    };

    /**
     * Appends a new div element, inserting it in the correct order according to what's in containerLayerOrder
     * @param {string} layerOrderId - the id for the newly created div
     */
    Viewer3D.prototype.appendOrderedElementToViewer = function(layerOrderId) {
        var existingElement = this.container.querySelector('[layer-order-id="' + layerOrderId + '"]');

        if (existingElement) {
            return existingElement;
        }

        var element = document.createElement('div');
        element.setAttribute('layer-order-id', layerOrderId);

        this.reorderElements(element);

        return element;
    };

    /**
     * Object that is returned by the ray cast and hit test methods for each scene object under the given canvas coordinates.
     * @typedef {object} Intersection
     * @property {number} dbId - Internal ID of the scene object.
     * @property {number} distance - Distance of the intersection point from camera. All intersections
     *  returned by the ray casting method are sorted from the smallest distance to the largest.
     * @property {THREE.Face3} face - THREE.Face3 object
     *  representing the triangular mesh face that has been intersected.
     * @property {number} faceIndex - Index of the intersected face, if available.
     * @property {number} fragId - ID of Forge Viewer *fragment* that was intersected.
     * @property {THREE.Vector3} point - THREE.Vector3 point of intersection.
     * @property {Autodesk.Viewing.Model} model - Model instance the dbId belongs to.
     */

    /**
     * Returns the intersection information for point x,y. If no intersection is found this function will return null.
     * @param {number} x - X-coordinate, i.e., horizontal distance (in pixels) from the left border of the canvas.
     * @param {number} y - Y-coordinate, i.e., vertical distance (in pixels) from the top border of the canvas.
     * @param {boolean} [ignoreTransparent] - ignores any transparent objects that might intersect x,y 
     * @returns {Intersection|null} - Intersection information about closest hit point.        
     * 
     * @alias Autodesk.Viewing.Viewer3D#hitTest
     */
    Viewer3D.prototype.hitTest = function(x, y, ignoreTransparent) {
        return this.impl.hitTest(x, y, ignoreTransparent);
    }

    /**
     * Clears the screen and redraws the overlays if *clear* is set to true.
     * Only the overlays will be redrawn if *clear* is set to false.
     * Should only be called when absolutely needed.
     * 
     * @param {boolean} clear - clears the screen and redraws the overlays.
     * @alias Autodesk.Viewing.Viewer3D#refresh
     */
    Viewer3D.prototype.refresh = function(clear) {
        this.impl.invalidate(clear, false, !clear);
    }